import React from "react";

const ChatButton = () => {
  const initialiseChat = () => {
    const features = "width=500, height=500, left=1600, top=650";
    window.open("/chat.html", "", features);
  };

  // return <button onClick={() => initialiseChat()}>Chat</button>;
  return (
    <div
      className="btn-chat"
      id="livechat-compact-container"
      style={{ visibility: "visible", opacity: "1" }}
    >
      <div className="btn-holder" style={{ height: "0px !important" }}>
        <a onClick={() => initialiseChat()} className="link" role="button">
          Live Chat
        </a>
      </div>
    </div>
  );
};

export default ChatButton;
