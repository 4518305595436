import {
  BOOKING_STORE_STATUS,
  BOOKING_VALIDATE_STATUS,
  TOGGLE_MODAL,
  TOGGLE_CHECK_AVAIL_BUTTON,
  BOOKING_AVAILABILITY_CHECK_STATUS,
  BOOKING_FETCH_OPTION_STATUS,
  BOOKING_ADD_STATUS,
  SEND_EMAIL_STATUS,
} from "./types";
import axios from "axios";
import { checkAvailability, getDates } from "../helpers/bookingFunctions";
import Moment from "moment";
import verAPI from "../apis/veraApi";
import _ from "lodash";

function validateBooking() {
  // validate  fields

  return true;
}

function getMultiAvailability(from, to, code) {
  return axios({
    method: "post",
    url:
      "https://vera.trn.secure-travel.net/vera/api/search/getOptionMultiAvail",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      providers: ["tp"],
      agent: "VIVB2C",
      password: "lovethatvivafeeling",
      opts: [code],
      multiDateFrom: from,
      multiDateTo: to,
    },
  })
    .then((response) => {
      //console.log(response.data.Response.tp);
      return response.data.Response.tp;
    })
    .catch(function (error) {
      return error;
    });
}

function getAvailability(from, to, code) {
  return axios({
    method: "post",
    url: "https://vera.trn.secure-travel.net/vera/api/search/getOptionAvail",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      providers: ["tp"],
      agent: "VIVB2C",
      password: "lovethatvivafeeling",
      opts: ["SYDAC188SYDRMC"],
      dateFrom: from,
      dateTo: to,
    },
  })
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

function getOptions(from, to, code, adults, children, ages) {
  return axios({
    method: "post",
    url: "http://vera.trn.secure-travel.net:8080/vera/api/search/getOpt",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      providers: ["tp"],
      agent: "VIVB2C",
      password: "lovethatvivafeeling",
      applicationId: "vivaconsumer",
      opt: code,
      dateFrom: Moment(from).format("YYYY-MM-DD"),
      dateTo: Moment(to).format("YYYY-MM-DD"),
      adults: adults,
      children: children,
      childAges: ages,
      returnOptionInfo: "Y",
      shoppingMode: "Y",
      agentSpecific: "Y",
      locationType: "",
      locationCode: "",
    },
  })
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

function addBookingapi(
  passenger,
  title,
  firstName,
  lastName,
  email,
  phoneNo,
  addressLine,
  suburb,
  state,
  postcode,
  opt,
  from,
  to,
  numberOfAdults,
  children,
  inafnts,
  roomType,
  paxlist
) {
  return axios({
    method: "post",
    url: "http://vera.trn.secure-travel.net:8080/vera/api/book/addBooking",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      providers: ["tp"],
      agent: "VIVB2C",
      password: "lovethatvivafeeling",
      applicationId: "vivaconsumer",
      bookingReference: "",
      passenger: passenger,
      contactDetails: {
        title: title,
        foreName: firstName,
        lastName: lastName,
        email: email,
        phone: phoneNo,
        address: addressLine,
        city: suburb,
        state: state,
        postCode: postcode,
        country: "Australia", //NEED TO ADD A COUNTRY FIELD
      },
      comments: "Test Comments",
      services: [
        {
          opt: opt,
          dateFrom: from,
          dateTo: to,
          roomConfig: {
            adults: numberOfAdults,
            children: children,
            infants: inafnts,
            roomType: roomType,
            paxList: paxlist,
          },
          puTime: "",
          puRemark: "",
          doTime: "",
        },
      ],
    },
  })
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

function sendEmail(email, firstName, booking_ref) {
  return axios({
    method: "post",
    url: "https://vera.trn.secure-travel.net/mailman/api/email/send",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      application: "lg",
      subject: "Luxury Getaways Booking Confirmation",
      emailTo: [email],
      //"emailCc": ["Ken.Baker@helloworld.com.au"],
      emailBcc: ["Ken.Baker@helloworld.com.au"],
      name: firstName,
      bookingRef: "TESTBOOKING",
      emailBody: "Testing",
      attURL: "https://pdf.didgigo.com/s0TG0LyONX",
    },
  })
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      //console.log(error);
      return error;
    });
}

export function fetchMultiAvailability(from, to, code) {
  let arr = [];
  let datesarr = [];
  return (dispatch) => {
    dispatch(fetchMultiAvailabilityBegin());
    return getMultiAvailability(from, to, code)
      .then((result) => {
        //console.log(result);
        if (result != null) {
          dispatch(fetchMultiAvailabilitySuccess(result));

          result.forEach(function (item, i) {
            //console.log(item.optAvail);

            arr.push({
              dates: result[i].dateFrom + "_" + result[i].dateTo,
              optAvail: item.optAvail,
            });
          });

          //console.log(result[0].dateFrom + "_" + result[0].dateTo);

          arr.forEach(function (item, i) {
            let options = item.optAvail.split(" ");
            let end = new Date(
              arr[i].dates.split("_")[1].split("-")[0],
              arr[i].dates.split("_")[1].split("-")[1] - 1,
              (Number(arr[i].dates.split("_")[1].split("-")[2]) + 1).toString()
            );
            let start = new Date(
              arr[i].dates.split("_")[0].split("-")[0],
              arr[i].dates.split("_")[0].split("-")[1] - 1,
              (Number(arr[i].dates.split("_")[0].split("-")[2]) + 1).toString()
            );
            let dates = getDates(start, end);

            dates.forEach(function (obj, i) {
              if (options[i] < 1) {
                new Date(obj.setDate(obj.getDate() - 1));
                datesarr.push(obj);
              }
            });
          });

          //console.log(datesarr);
          dispatch(fetchMultiAvailabilityBlocked(datesarr));
        } else {
          fetchMultiAvailabilityFailure("Not Available");
        }
        return result;
      })
      .catch((error) => dispatch(fetchMultiAvailabilityFailure(error)));
  };
}

export function fetchAvailability(
  from,
  to,
  code,
  adults,
  children,
  ages,
  cms_price
) {
  return (dispatch) => {
    dispatch(fetchAvailabilityBegin());
    return getAvailability(from, to, code)
      .then((result) => {
        if (result.data.Response.tp != null) {
          let numAvail = result.data.Response.tp[0].optAvail.split(" ");

          if (checkAvailability(numAvail)) {
            dispatch(fetchAvailabilitySuccess(result));

            dispatch(
              fetchOptions(from, to, code, adults, children, ages, cms_price)
            );
          } else {
            dispatch(fetchAvailabilityFailure("Not Available"));
          }
        } else {
          fetchAvailabilityFailure("Not Available");
        }
        return result;
      })
      .catch((error) => dispatch(fetchAvailabilityFailure(error)));
  };
}

export function fetchOptions(
  from,
  to,
  code,
  adults,
  children,
  ages,
  cms_price
) {
  return (dispatch) => {
    dispatch(fetchOptionsBegin());
    return getOptions(from, to, code, adults, children, ages)
      .then((result) => {
        if (
          result.data.Response.options == null ||
          result.data.Response.options[0].availability == "RQ" ||
          result.data.Response.options[0] == undefined
        ) {
          dispatch(fetchOptionsFailure("ERROR"));
        } else {
          dispatch(fetchOptionsSuccess(result));
          dispatch(
            StoreBookingTotalPrice(
              "$" + parseInt(cms_price * adults).toLocaleString()
            )
          );
          dispatch(StoreBookingGetOpt(result.data.Response.options[0]));
          dispatch(toggleAvailButton(false));
        }

        return result;
      })
      .catch((error) => dispatch(fetchOptionsFailure(error)));
  };
}

export function fetchEmailSend(email, firstName, ref) {
  return (dispatch) => {
    dispatch(fetchSendEmailBegin());
    return sendEmail(email, firstName, ref)
      .then((result) => {
        //console.log(result);
        return result;
      })
      .catch((error) => dispatch(fetchSendEmailFailure(error)));
  };
}

export function addBooking(
  passenger,
  title,
  firstName,
  lastName,
  email,
  phoneNo,
  addressLine,
  suburb,
  state,
  postcode,
  opt,
  from,
  to,
  numberOfAdults,
  children,
  inafnts,
  roomType,
  paxlist
) {
  let bookingInfo = "";

  return (dispatch) => {
    dispatch(addBookingBegin());
    return addBookingapi(
      passenger,
      title,
      firstName,
      lastName,
      email,
      phoneNo,
      addressLine,
      suburb,
      state,
      postcode,
      opt,
      from,
      to,
      numberOfAdults,
      children,
      inafnts,
      roomType,
      paxlist
    )
      .then((response) => {
        dispatch(addBookingSuccess(response));

        if (response.data.Response.length == 0) {
          addBookingFailure("error");
        } else {
          bookingInfo = response.data.Response[0];
          dispatch(
            fetchEmailSend(email, firstName, response.data.Response[0].ref)
          ).then((response) => {
            console.log(response.toString() == "Error: Network Error");
            if (!(response.toString() == "Error: Network Error")) {
              dispatch(fetchSendEmailSuccess(response));
              dispatch(StoreBookingInfo(bookingInfo));
              dispatch(StoreBookingRedirect(true));
            } else {
              dispatch(fetchSendEmailFailure(response));
            }
          });
        }

        return response;
      })
      .catch((error) => dispatch(addBookingFailure(error)));
  };
}

export const fetchMultiAvailabilityBlocked = (payload) => ({
  type: BOOKING_AVAILABILITY_CHECK_STATUS.BLOCKED_DATES,
  payload: { payload },
});

export const fetchMultiAvailabilityBegin = () => ({
  type: BOOKING_AVAILABILITY_CHECK_STATUS.MULTI_BEGIN,
});

export const fetchMultiAvailabilitySuccess = (payload) => ({
  type: BOOKING_AVAILABILITY_CHECK_STATUS.MULTI_SUCCESS,
  payload: { payload },
});

export const fetchMultiAvailabilityFailure = (error) => ({
  type: BOOKING_AVAILABILITY_CHECK_STATUS.MULTI_FAILURE,
  payload: { error },
});

export const fetchAvailabilityBegin = () => ({
  type: BOOKING_AVAILABILITY_CHECK_STATUS.BEGIN,
});

export const fetchAvailabilitySuccess = (payload) => ({
  type: BOOKING_AVAILABILITY_CHECK_STATUS.SUCCESS,
  payload: { payload },
});

export const fetchAvailabilityFailure = (error) => ({
  type: BOOKING_AVAILABILITY_CHECK_STATUS.FAILURE,
  payload: { error },
});

export const fetchOptionsBegin = () => ({
  type: BOOKING_FETCH_OPTION_STATUS.BEGIN,
});

export const fetchOptionsSuccess = (payload) => ({
  type: BOOKING_FETCH_OPTION_STATUS.SUCCESS,
  payload: { payload },
});

export const fetchOptionsFailure = (error) => ({
  type: BOOKING_FETCH_OPTION_STATUS.FAILURE,
  payload: { error },
});

export const addBookingBegin = () => ({
  type: BOOKING_ADD_STATUS.BEGIN,
});

export const addBookingSuccess = (payload) => ({
  type: BOOKING_ADD_STATUS.SUCCESS,
  payload: { payload },
});

export const addBookingFailure = (error) => ({
  type: BOOKING_ADD_STATUS.FAILURE,
  payload: { error },
});

export const fetchSendEmailBegin = () => ({
  type: SEND_EMAIL_STATUS.BEGIN,
});

export const fetchSendEmailSuccess = (payload) => ({
  type: SEND_EMAIL_STATUS.SUCCESS,
  payload: { payload },
});

export const fetchSendEmailFailure = (error) => ({
  type: SEND_EMAIL_STATUS.FAILURE,
  payload: { error },
});

export const validateBookingBegin = () => ({
  type: BOOKING_VALIDATE_STATUS.BEGIN,
});

export const validateBookingSuccess = (booking) => ({
  type: BOOKING_VALIDATE_STATUS.SUCCESS,
  payload: { booking },
});

export const validateBookingFailure = (error) => ({
  type: BOOKING_VALIDATE_STATUS.FAILURE,
  payload: { error },
});

export const StoreBookingTravelRangeFrom = (payload) => ({
  type: BOOKING_STORE_STATUS.STORE_TRAVEL_RANGE_FROM,
  payload: payload,
});

export const StoreBookingTravelRangeTo = (payload) => ({
  type: BOOKING_STORE_STATUS.STORE_TRAVEL_RANGE_TO,
  payload: payload,
});

export const StoreBookingAdultTravellers = (payload) => ({
  type: BOOKING_STORE_STATUS.STORE_TRAVELLERS_ADULT,
  payload: payload,
});

export const StoreBookingChildTravellers = (payload) => ({
  type: BOOKING_STORE_STATUS.STORE_TRAVELLERS_CHILD,
  payload: payload,
});

export const StoreBookingPassengers = (payload) => ({
  type: BOOKING_STORE_STATUS.STORE_PASSENGERS,
  payload: payload,
});

export const ClearBookingPassengers = (payload) => ({
  type: BOOKING_STORE_STATUS.CLEAR_PASSENGERS,
  payload: payload,
});

export const StoreBookingAddress = (payload) => ({
  type: BOOKING_STORE_STATUS.STORE_ADDRESS,
  payload: payload,
});

export const StoreBookingTotalPrice = (payload) => ({
  type: BOOKING_STORE_STATUS.STORE_PRICE,
  payload: payload,
});

export function toggleModal() {
  return {
    type: TOGGLE_MODAL.PAYMENT,
  };
}

export const toggleAvailButton = (payload) => ({
  type: TOGGLE_CHECK_AVAIL_BUTTON.TOGGLE,
  payload: payload,
});

export const StoreBookingGetOpt = (payload) => ({
  type: BOOKING_STORE_STATUS.STORE_GET_OPT_RESPONSE,
  payload: payload,
});

export const StoreBookingInfo = (payload) => ({
  type: BOOKING_STORE_STATUS.STORE_BOOKING_INFO_RESPONSE,
  payload: payload,
});

export const StoreBookingRedirect = (payload) => ({
  type: BOOKING_STORE_STATUS.STORE_BOOKING_REDIRECT,
  payload: payload,
});

export const StoreBookingPackageConditions = (payload) => ({
  type: BOOKING_STORE_STATUS.STORE_PACKAGE_CONDITIONS,
  payload: payload,
});

export const StoreBookingPackageDescription = (payload) => ({
  type: BOOKING_STORE_STATUS.STORE_PACKAGE_DESCRIPTION,
  payload: payload,
});

export const StoreBookingPackageIncludes = (payload) => ({
  type: BOOKING_STORE_STATUS.STORE_PACKAGE_INCLUDES,
  payload: payload,
});

export const StoreBookingPackageName = (payload) => ({
  type: BOOKING_STORE_STATUS.STORE_PACKAGE_NAME,
  payload: payload,
});

export const StoreCurrentBooking = (payload) => ({
  type: BOOKING_STORE_STATUS.STORE_CURRENT_BOOKING,
  payload: payload,
});

export const sendBooking = (formValues) => async (dispatch) => {
  // const result = _.pickBy(formValues, (value, key) =>
  //   key.startsWith("firstName_")
  // );

  let arr = _.fill(Array(2), null);

  let res = _.map(arr, (key, val) => {
    return _.pickBy(formValues, (value, key) => key.endsWith(`_${val + 1}`));
  });

  let paxAdultList = [];

  res.map((item) => {
    paxAdultList.push(
      _.mapKeys(item, (value, key) => {
        let newKey = key;
        if (key.includes("title")) {
          newKey = "title";
        }
        if (key.includes("firstName")) {
          newKey = "foreName";
        }
        if (key.includes("lastName")) {
          newKey = "lastName";
        }
        return newKey;
      })
    );
  });

  let fromDate = Moment(formValues.from).format("YYYY-MM-DD HH:mm:ss");

  const response = await verAPI
    .post("book/addBooking", {
      providers: ["tp"],
      agent: "VIVB2C",
      password: "lovethatvivafeeling",
      applicationId: "vivaconsumer",
      passenger: `${formValues.booker_lastName}/${formValues.booker_title}${formValues.booker_firstName}`,
      contactDetails: {
        title: formValues.booker_title,
        foreName: formValues.booker_firstName,
        lastName: formValues.booker_lastName,
        email: formValues.email,
        phone: formValues.phone,
        address: formValues.street,
        city: formValues.suburb,
        state: formValues.state,
        postCode: formValues.postcode,
        country: "AU", //NEED TO ADD A COUNTRY FIELD
      },
      comments: "Test Comments",
      services: [
        {
          opt: "SYDAC188SYDRMC",
          dateFrom: Moment(formValues.from).format("YYYY-MM-DD HH:mm:ss"),
          dateTo: Moment(formValues.to).format("YYYY-MM-DD HH:mm:ss"),
          roomConfig: {
            adults: formValues.noAdult,
            children: formValues.noChild,
            infants: formValues.noInfant,
            roomType: "DB",
            paxList: paxAdultList.map((item) => {
              return { ...item, paxType: "A" };
            }),
          },

          puTime: "",
          puRemark: "",
          doTime: "",
        },
      ],
    })
    .catch((error) => {
      //todo show booking unavailable
      console.log(error);
    });

  //dispatch({ type: 'TEST', payload: response.data });
};

export const saveBookingDetails = (payload) => ({
  type: BOOKING_STORE_STATUS.STORE_SAVE_BOOKING_DETAILS,
  payload: payload,
});

export const savePaymentReply = (payload) => ({
  type: BOOKING_STORE_STATUS.STORE_SAVE_PAYMENT_DETAILS,
  payload: payload,
});

export const saveBookingTP = (payload) => ({
  type: BOOKING_STORE_STATUS.STORE_SAVE_BOOKING_DETAILS_TP,
  payload: payload,
});

export const saveItem = (payload) => ({
  type: BOOKING_STORE_STATUS.STORE_SAVE_ITEM,
  payload: payload,
});
