import vera from "../apis/vera";
import drupal from "../apis/drupal";
import { DS_ACTIONS } from "../actions/types";
import _ from "lodash";

export const fetchDestinations = () => async (dispatch) => {
  const response = await drupal.get("/destinations");
  dispatch({ type: DS_ACTIONS.FETCH_DESTINATIONS, payload: response.data });
};

export const getDestinationCities = (id) => async (dispatch) => {
  try {
    const response = await vera.get(
      `/travelguide/api/travelguides/getDestinations/${id}`
    );

    dispatch({
      type: DS_ACTIONS.FETCH_COUNTRY_CITIES,
      payload: response.data,
    });
  } catch (error) {
    console.log(error);
  }
};

// export const getCountryDetail = id => async dispatch => {
//   const response = await drupal.get(`/destination/${id}`);
//   dispatch({
//     type: DS_ACTIONS.FETCH_COUNTRY_DETAIL,
//     payload: response.data
//   });
// };

export const getCountryDetail = (id) => async (dispatch) => {
  const cities = await vera.get(
    `/travelguide/api/travelguides/getDestinations/${id}`
  );
  const detail = await drupal.get(`/destination/${id}`);

  dispatch({
    type: DS_ACTIONS.FETCH_COUNTRY_DETAIL,
    payload: {
      detail: detail.data[0],
      cities: cities.data,
    },
  });
};

export const fetchAllCountries = (code) => async (dispatch, getState) => {
  const destState =
    process.env.REACT_APP_MODE === "goholsnz"
      ? getState().goPersist.destinations
      : getState().vivaPersist.destinations;

  if (_.isEmpty(destState)) {
    const response = await vera.get(
      `/travelguide/api/travelguides/getAllCountries`
    );

    //add path and card url
    const data = _.chain(response.data)
      .sortBy("title")
      .map((country) => {
        let origHeroURL = country.field_main_image.replace(
          "width=420&height=252&",
          ""
        );
        let heroPath = origHeroURL.split("/").slice(-2).join("/");
        //final url with crop applied
        let heroURL = "https://image.arrivalguides.com/325x145/" + heroPath;
        let title = country.title.replace(/\s+/g, "-").toLowerCase();
        return {
          ...country,
          field_path: title === "united-states" ? "usa" : title,
          field_card_image: heroURL,
        };
      })
      .value();

    dispatch({ type: DS_ACTIONS.FETCH_ALL_COUNTRIES, payload: data });
  }
};

export const getCityEntries = (id) => async (dispatch) => {
  const response = await vera.get(
    `/travelguide/api/travelguides/getDestinationEntries/${id}`
  );
  dispatch({
    type: DS_ACTIONS.FETCH_CITY_ENTRIES,
    payload: { data: response.data, city: id },
  });
};

export const getCityDetail = (id) => async (dispatch) => {
  const response = await vera.get(
    `/travelguide/api/travelguides/getDestinationDetails/${id}`
  );
  dispatch({
    type: DS_ACTIONS.FETCH_CITY_DETAIL,
    payload: { data: response.data, city: id },
  });
};
