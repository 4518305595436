import React, { useEffect, useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PersistedContext from "../../context/persistedContext";
import BookingCheckout from "./BookingCheckout";
import history from "../../history";
import { Row, Col } from "react-bootstrap";
import { addBooking } from "../../helpers/addBooking";
import { cancelBooking } from "../../helpers/cancelBooking";
import { sendPayment } from "../../helpers/sendPayment";
import BookingCheckoutCart from "./BookingCheckoutCart";
import { loader, emailReport } from "../../helpers";
import { sendEmail } from "../../helpers/sendEmail";
import _, { isEmpty } from "lodash";
import {
  savePaymentReply,
  saveBookingDetails,
  saveBookingTP,
} from "../../actions/bookingActions";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";

const BookingCheckoutContainer = (props) => {
  const { item, paymentReply } = useContext(PersistedContext);

  const [loading, setLoading] = useState(false);
  const [cardType, setCardType] = useState("");

  //const booking = props.location.booking && props.location.booking;

  const dispatch = useDispatch();

  useEffect(() => {
    if (_.isEmpty(item)) {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    async function fetchToken() {
      const token = await props.googleReCaptchaProps.executeRecaptcha(
        "homepage"
      );
    }
    fetchToken();
  }, []);

  const bookingForm = async (formValues) => {
    setLoading(true);
    let paymentReply = null;
    let bookingReply = null;
    let tpError = null;

    const tkn = await props.googleReCaptchaProps.executeRecaptcha("homepage");

    formValues = { ...formValues, ccType: cardType };

    try {
      bookingReply = await addBooking(formValues);
      if (!bookingReply.ref) {
        tpError = bookingReply;
        bookingReply = null;
      }
    } catch (error) {
      console.log(error);
    }

    if (bookingReply) {
      paymentReply = await sendPayment(formValues, bookingReply);
      sendBookingReport(tpError, bookingReply, paymentReply, formValues, tkn);
    }

    if (bookingReply && paymentReply && paymentReply.data.transactionStatus) {
      dispatch(saveBookingDetails(formValues));
      dispatch(saveBookingTP(bookingReply));
      dispatch(savePaymentReply(paymentReply.data));
      history.push("/booking-confirmation");
    } else if (paymentReply) {
      dispatch(savePaymentReply(paymentReply.data));
      await cancelBooking(bookingReply.ref);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const sendBookingReport = async (
    tpError,
    bookingReply,
    paymentReply,
    formValues,
    tkn
  ) => {
    const message = emailReport(
      tpError,
      bookingReply,
      paymentReply,
      formValues
    );

    let ref = bookingReply ? bookingReply.ref : "NOREF";

    let emailMessage = {
      application: "default",
      subject: `Viva Holidays Booking Request`,
      emailTo: [
        "Anthoney.Woodyard@helloworld.com.au",
        "Prasad.Nanganoori@helloworld.com.au",
        "ken.baker@helloworld.com.au",
        "Tammy-Lee.Butler@helloworld.com.au",
      ],
      //emailBcc: ["Melissa.Warren@helloworld.com.au"],
      //emailTo: ["ken.baker@helloworld.com.au"],
      name: "Name: Report",
      emailBody: `${message}`,
      token: tkn,
      bookingRef: ref,
    };

    let res = await sendEmail(emailMessage);

    //res.status == 200 && this.setState({ messageFlag: true });
    //this.props.subscribe(formValues.email);
  };

  return loading || _.isEmpty(item) ? (
    loader("Confirming booking, please wait...")
  ) : (
    <Row>
      {item && (
        <Col className="order-md-2 mb-5" md="4">
          <BookingCheckoutCart items={item} />
        </Col>
      )}
      <Col className="mb-3">
        <BookingCheckout
          bkgForm={bookingForm}
          items={item}
          setCardType={setCardType}
          payment={paymentReply}
        />
      </Col>
    </Row>
  );
};

const EnquiryFormRecaptcha = withGoogleReCaptcha(BookingCheckoutContainer);

export default EnquiryFormRecaptcha;
