import React, { lazy } from "react";
import { SEO } from "../seo/seo";
import BookingCheckoutContainer from "../components/bookings/BookingCheckoutContainer";

const DestinationList = lazy(() =>
  import("../components/destinations/DestinationList")
);
const DestinationCityList = lazy(() =>
  import("../components/destinations/country/DestinationCityList")
);
const DestinationCityDetail = lazy(() =>
  import("../components/destinations/city/DestinationCityDetail")
);

//const HomePage = lazy(() => import("../components/home/HomePage"));

//Promo HomePage July 2020
const HomePage = lazy(() => import("../components/home/promos/AusSalePage"));
const HomePageGoNZ = lazy(() => import("../components/home/HomePage"));

const BrochurePage = lazy(() => import("../components/brochure/BrochurePage"));
const PackageList = lazy(() => import("../components/packages/PackageList"));
const PackagePage = lazy(() => import("../components/packages/PackagePage"));

const UltimateJourneyList = lazy(() => import("../components/ultimate-journeys/UltimateJourneyList"));
const UltimateJourneyPage = lazy(() => import("../components/ultimate-journeys/UltimateJourneyPage"));

const EscortedTourList = lazy(() => import("../components/escorted-tours/EscortedTourList"));
const EscortedTourPage = lazy(() => import("../components/escorted-tours/EscortedTourPage"));

const DealPage = lazy(() => import("../components/deals/DealPage"));
const DealList = lazy(() => import("../components/deals/DealList"));
const NewsList = lazy(() => import("../components/news/NewsList"));
const NewsPage = lazy(() => import("../components/news/NewsPage"));
const BookingForm = lazy(() =>
  import("../components/bookings/BookingCheckoutContainer")
);
const BookingConfirmation = lazy(() =>
  import("../components/bookings/BookingConfirmation")
);

const CMSPage = lazy(() => import("../components/cms-pages/Pages"));

const VoucherPage = lazy(() =>
  import("../components/gift-voucher/VoucherPage")
);

//preview components
const PreviewPage = lazy(() => import("../components/preview/PreviewRedirect"));

const PreviewPackagePage = lazy(() =>
  import("../components/preview/PreviewPackage")
);

const PreviewDealPage = lazy(() => import("../components/preview/PreviewDeal"));

const TITLE = "Viva Holidays";

const withTitle = ({ component: Component, title }) => {
  return class Title extends React.Component {
    render() {
      return (
        <>
          <SEO title={title} />
          <Component {...this.props} />
        </>
      );
    }
  };
};

export const HomeComponent = withTitle({
  component:
    process.env.REACT_APP_MODE === "goholsnz" ? HomePageGoNZ : HomePage,
  title: TITLE,
});

export const DestinationListComponent = withTitle({
  component: DestinationList,
  title: TITLE,
});

export const DestinationCityComponent = withTitle({
  component: DestinationCityList,
  title: TITLE,
});

export const DestinationCityDetailComponent = withTitle({
  component: DestinationCityDetail,
  title: TITLE,
});

export const BrochureComponent = withTitle({
  component: BrochurePage,
  title: "Brochures",
});

export const PackageListComponent = withTitle({
  component: PackageList,
  title: "Packages",
});

export const PackagePageComponent = withTitle({
  component: PackagePage,
  title: "Packages",
});

export const UltimateJourneyListComponent = withTitle({
  component: UltimateJourneyList,
  title: "Ultimate Journeys",
});

export const UltimateJourneyPageComponent = withTitle({
  component: UltimateJourneyPage,
  title: "Ultimate Journeys",
});

export const EscortedTourListComponent = withTitle({
  component: EscortedTourList,
  title: "Escorted Tours",
});

export const EscortedTourPageComponent = withTitle({
  component: EscortedTourPage,
  title: "Escorted Tours",
});

export const DealPageComponent = withTitle({
  component: DealPage,
  title: "Deals",
});

export const DealListComponent = withTitle({
  component: DealList,
  title: "Deals",
});

export const NewsListComponent = withTitle({
  component: NewsList,
  title: "News",
});

export const NewsPageComponent = withTitle({
  component: NewsPage,
  title: "News",
});

export const PolicyComponent = withTitle({
  component: CMSPage,
  title: "Booking Conditions",
});

export const AboutComponent = withTitle({
  component: CMSPage,
  title: "About Viva Holidays",
});

export const BookWithConfidenceComponent = withTitle({
  component: CMSPage,
  title: "Book With Confidence",
});

export const ContactComponent = withTitle({
  component: CMSPage,
  title: "Contact Viva Holidays",
});

export const VoucherComponent = withTitle({
  component: VoucherPage,
  title: "Gift Vouchers",
});

export const BookingFormContainer = withTitle({
  component: BookingForm,
  title: "Booking Viva Holidays",
});

export const BookingConfirm = withTitle({
  component: BookingConfirmation,
  title: "Booking Confirmed",
});

export const PreviewComponent = withTitle({
  component: PreviewPage,
  title: "Preview",
});

export const PreviewPackageComponent = withTitle({
  component: PreviewPackagePage,
  title: "Preview",
});

export const PreviewDealComponent = withTitle({
  component: PreviewDealPage,
  title: "Preview",
});
