import Moment from "moment";
import veraAPI from "../apis/veraApi";
import _ from "lodash";

export async function addBooking(formValues) {
  let response = "";
  //let arr = _.fill(Array(2), null);
  let arr = _.fill(Array(parseInt(formValues.noAdult)), null);
  let res = _.map(arr, (key, val) => {
    return _.pickBy(formValues, (value, key) => key.endsWith(`_${val + 1}`));
  });

  let paxAdultList = [];

  res.map((item) => {
    paxAdultList.push(
      _.mapKeys(item, (value, key) => {
        let newKey = key;
        if (key.includes("title")) {
          newKey = "title";
        }
        if (key.includes("firstName")) {
          newKey = "foreName";
        }
        if (key.includes("lastName")) {
          newKey = "lastName";
        }
        return newKey;
      })
    );
  });

  let fromDate = Moment(formValues.from).format("YYYY-MM-DD HH:mm:ss");

  response = await veraAPI
    .post("book/addBooking", {
      providers: ["tp"],
      agent: process.env.REACT_APP_TP_AGENT_VIVA_AU,
      password: process.env.REACT_APP_TP_PASS_VIVA_AU,
      applicationId: "vivaconsumer",
      passenger: `${formValues.booker_lastName}/${formValues.booker_title}${formValues.booker_firstName}`,
      sendSupplierMessage: "Y",
      contactDetails: {
        title: formValues.booker_title,
        foreName: formValues.booker_firstName,
        lastName: formValues.booker_lastName,
        email: formValues.email,
        phone: formValues.phone,
        address: formValues.street,
        city: formValues.suburb,
        state: formValues.state,
        postCode: formValues.postcode,
        country: "AU", //NEED TO ADD A COUNTRY FIELD
      },
      comments: formValues.message,
      services: [
        {
          opt: formValues.optCode,
          dateFrom: Moment(formValues.fromDate).format("YYYY-MM-DD"),
          dateTo: Moment(formValues.toDate)
            .subtract(1, "days")
            .format("YYYY-MM-DD"),
          roomConfig: {
            adults: formValues.noAdult,
            children: formValues.noChild,
            infants: formValues.noInfant,
            roomType: "DB",
            paxList: paxAdultList.map((item) => {
              return { ...item, paxType: "A" };
            }),
          },

          puTime: "",
          puRemark: "",
          doTime: "",
        },
      ],
    })
    .catch((error) => {
      //response = error;
      console.log(error);
    });

  console.log(response);

  response = response.data.Response
    ? response.data.Response.results[0]
    : response.data;

  return response;
}
