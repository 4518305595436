import React from "react";
import { Link } from "react-router-dom";
import { Row } from "react-bootstrap";
import SubscribeElement from "../../elements/Subscribe";
import { default as BASE } from "../../config";
import { footerLogos } from "../../helpers/footerLogos";

const Footer = () => {
  return (
    <div>
      <footer className="footer text-center">
        {/* <row> */}
        <Row className="p-0 m-0">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-2 social-links pt-3">
            <p className="small text-uppercase">Connect with us</p>
            <ul className="nav justify-content-center">
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://www.instagram.com/vivaholidays"
                  target="_blank"
                >
                  <i className="fab fa-instagram text-white"></i>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://www.facebook.com/VivaHolidaysAU/"
                  target="_blank"
                >
                  <i className="fab fa-facebook-f text-white"></i>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://twitter.com/viva_holidays"
                  target="_blank"
                >
                  <i className="fab fa-twitter text-white"></i>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://www.youtube.com"
                  target="_blank"
                >
                  <i className="fab fa-youtube text-white"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-10">
            <Row className="p-md-3 pt-lg-3">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 pt-2 copyrightPolicies text-left d-none d-lg-block">
                <p className="m-0 small">
                  &copy; 2020 {BASE.CONFIG.COPYRIGHT_TAG}
                </p>
                <a
                  href="https://policies.helloworldlimited.com.au/cookies-policy"
                  target="_blank"
                  className="small pr-2 text-white"
                >
                  Cookie Policy
                </a>
                <a
                  href="https://policies.helloworldlimited.com.au/privacy-policy"
                  target="_blank"
                  className="small pr-2 text-white"
                >
                  Privacy Policy
                </a>
                {/* <Link
                  className="small pr-2 text-white"
                  to={`/policies/privacy-policy`}
                >
                  Privacy Policy
                </Link> */}
                <Link
                  className="small pr-2 text-white"
                  to={`/policies/booking-conditions`}
                >
                  Booking Conditions
                </Link>
                {/* <a
                  href="https://www.helloworld.com.au/helloworld-terms-and-condition?terms-and-conditions"
                  target="_blank"
                  className="small pr-2 text-white"
                >
                  Booking Conditions
                </a> */}
                <Link className="small pr-2" to={`/#`} target="_blank"></Link>
              </div>
              {/* START OF SUBSCRIBE */}
              {/* <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                <SubscribeElement />
              </div> */}
              {/* END OF SUBSCRIBE */}
            </Row>
          </div>
        </Row>
        {/* </row> */}
      </footer>
      {footerLogos()}
    </div>
  );
};

export default Footer;
