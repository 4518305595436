import React, { useState } from "react";
import useFocus from "../custom-hooks/useFocus";
import { Col, Form } from "react-bootstrap";
import Parser from "html-react-parser";
import _ from "lodash";
import { v1 as uuid } from "uuid";

const Filters = (props) => {
  const { content, baseContent } = props;
  const [title, setTitle] = useState("");
  const [dest, setDest] = useState(0);
  const [pkg, setPkg] = useState(props.defaultStyle);
  const [inputRef, setInputFocus] = useFocus(false);

  const titleField = (e) => {
    setTitle(e.target.value);
    props.setSrchTitle(e.target.value);
  };

  const destSelect = (e) => {
    setDest(e.target.value);
    props.setSrchDest(e.target.value);
  };

  const packageSelect = (e) => {
    setPkg(e.target.value);
    props.setSrchPkg(e.target.value);
  };

  const resetForm = (e) => {
    props.resetFilters();
    setTitle("");
    setDest(0);
    setPkg(0);
    setInputFocus(true);
  };

  let destinations = [];

  const destFilterOptions = () => {
    _.map(content, (obj) => {
      if (obj.field__campaign_destination) {
        let split = obj.field__campaign_destination
          .split(",")
          .map((val) => val.trim());
        destinations = [...destinations, ...split];
      }
      if (obj.field_local_location) {
        let split = obj.field_local_location
          .split(",")
          .map((val) => val.trim());
        destinations = [...destinations, ...split];
      }
    });

    let uniqDest = _.uniq(destinations.sort());

    {
      /* style={optionColor} */
    }

    return _.map(uniqDest, (val) => {
      return (
        <option value={val} key={uuid()}>
          {val}
        </option>
      );
    });
  };

  let packageStyles = [];

  const packageFilterOptions = () => {
    const pkgData = baseContent ? baseContent : content;

    _.chain(pkgData)
      .map((obj) => {
        let split =
          obj.field_consumer_deal_types &&
          obj.field_consumer_deal_types.split(",").map((val) => val.trim());

        packageStyles = [...packageStyles, ...split];
      })
      .value();

    let uniqPackages = _.uniq(packageStyles.sort());

    return _.map(uniqPackages, (val, id) => {
      return (
        <option value={val.toLowerCase()} key={uuid()}>
          {Parser(val.replace("-", " "))}
        </option>
      );
    });
  };

  const showResetButton = () => {
    if (title || isNaN(pkg) || isNaN(dest)) {
      return (
        <div className="container text-center">
          <a className="reset text-uppercase" onClick={(e) => resetForm(e)}>
            Reset
          </a>
        </div>
      );
    }
  };

  return (
    <div>
      <Form className="mb-4">
        <Form.Row className="m-0">
          <Col xs="12" md="4">
            <Form.Group controlId="formGridAddress1">
              <Form.Control
                placeholder="Search for a package"
                onChange={(e) => titleField(e)}
                value={title}
                ref={inputRef}
              />
            </Form.Group>
          </Col>
          <Col xs="12" md="4">
            <Form.Group controlId="formTypeList">
              <Form.Control
                placeholder="All Styles"
                as="select"
                value={pkg}
                onChange={(e) => packageSelect(e)}
              >
                <option value="0">All Styles</option>
                {packageFilterOptions()}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col xs="12" md="4">
            <Form.Group controlId="formDestList">
              <Form.Control
                placeholder="All Destinations"
                as="select"
                value={dest}
                onChange={(e) => destSelect(e)}
              >
                <option value="0">All Destinations</option>
                {destFilterOptions()}
              </Form.Control>
            </Form.Group>
          </Col>
          {showResetButton()}
        </Form.Row>

        <div className="text-center mt-4">
          {props.errorFlag && (
            <span className="font-weight-bold bg-danger rounded text-white p-2 small">
              No results found, see other packages below or{" "}
              <a className="reset" onClick={(e) => resetForm(e)}>
                <u>search again</u>
              </a>
            </span>
          )}
        </div>
      </Form>
    </div>
  );
};

export default Filters;
