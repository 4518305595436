import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Lightbox from "react-image-lightbox";
import "../assets/sass/components/Lightbox.scss";

const ImageGalleryLightbox = (props) => {
  const [images] = useState(props.gallery);
  const [alt] = useState(props.alt);
  const [caption] = useState(props.cap);
  const [photoIndex, setphotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  // console.log(alt);
  // console.log(caption);

  const renderImages = () => {
    let photoIndex = -1;

    return images.map((imageSrc, num) => {
      photoIndex++;
      const privateKey = photoIndex;
      return (
        <Col className="p-1" md="3" key={photoIndex}>
          <figure>
            <img
              src={imageSrc}
              alt={alt[num]}
              className="img-fluid deal-card p-1 bg-white"
              onClick={() => {
                setphotoIndex(privateKey);
                setIsOpen(true);
              }}
            />
          </figure>
        </Col>
      );
    });
  };

  return (
    <Container className="mt-3">
      <div className="enterprise-lightbox">
        <Row>{renderImages()}</Row>
      </div>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          imageCaption={caption[photoIndex]}
          imageTitle={caption[photoIndex]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setphotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setphotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </Container>
  );
};

export default ImageGalleryLightbox;
