import Moment from "moment";
import vera from "../apis/vera";
import _ from "lodash";

export async function sendEmail(formValues) {
  let response = "";

  response = await vera
    .post("/mailman/api/email/send", {
      ...formValues,
    })
    .catch((error) => {
      response = error;
    });

  return response;
}
