import React from "react";
import {
  Row,
  Container,
  Button,
  Modal,
  Spinner,
  Form,
  Col,
} from "react-bootstrap";
import { v1 as uuid } from "uuid";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import { loader } from "../../helpers";
import { connect } from "react-redux";
import _ from "lodash";
// import DateRangePickerField from "../elements/redux-form-elements/DateRangePicker";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";
import axios from "axios";
import moment from "moment";
import history from "../../history";
import { default as BASE } from "../../config";
import { phoneCodes } from "../../helpers/phoneCodes";
import {
  creditCardType,
  validateCreditCard,
} from "../../helpers/cardValidation";
import Title from "../../elements/Title";
import PmtOptions from "../../assets/img/payment-options.gif";
import Parser from "html-react-parser";
import { savePaymentReply } from "../../actions/bookingActions";
import ReactGA from "react-ga";

class BookingCheckout extends React.Component {
  state = {
    token: null,
    adultTot: null,
    childTot: null,
    location: null,
    loading: false,
    payErrors: false,
  };

  async componentDidMount() {
    const token = await this.props.googleReCaptchaProps.executeRecaptcha(
      "homepage"
    );

    let payErrors = false;
    let paymentStatus =
      this.props.payment && this.props.payment.transactionStatus;

    if (!_.isEmpty(this.props.payment) && !paymentStatus) {
      this.props.savePaymentReply(null);
      payErrors = true;
    }

    this.setState({
      token,
      //adultTot: this.props.totAdults ,
      adultTot: this.props.items.totAdults,
      childTot: this.props.items.totChildren,
      location: history.location.pathname,
      payErrors,
    });
  }

  renderError({ error, touched, label }) {
    if (touched && error) {
      return (
        <div className="invalid-feedback d-block">
          <div className="header">
            <strong>{error}</strong>
          </div>
        </div>
      );
    }

    if (label) {
      return (
        <div className="d-block">
          <div className="header">&nbsp;</div>
        </div>
      );
    }
  }

  renderInput = ({ placeholder, input, label, meta, type, disabled }) => {
    let cardNoDiv = "";
    let cardObj = null;

    cardObj = input.name === "cardNo" && creditCardType(input.value);

    return (
      <div className="form-group">
        {label && (
          <label className="form-label text-uppercase small">{label}</label>
        )}

        <div className="input-group">
          <input
            {...input}
            className="form-control"
            type={type}
            placeholder={placeholder}
            disabled={disabled}
          />
          {input.name === "cardNo" && (
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon">
                {input.name === "cardNo" &&
                !isNaN(input.value) &&
                input.value.length > 3 &&
                cardObj ? (
                  <img style={{ height: "30px" }} src={cardObj[0].image} />
                ) : (
                  <i className="fa fa-credit-card"></i>
                )}
              </span>
            </div>
          )}

          {this.renderError(meta, label)}
        </div>
      </div>
    );
  };

  renderTextArea = ({ placeholder, input, label, meta, type }) => {
    return (
      <div className="form-group">
        <label className="form-label">{label}</label>
        <textarea
          {...input}
          className="form-control"
          placeholder={placeholder}
          rows="4"
        />
        {this.renderError(meta)}
      </div>
    );
  };

  renderCheckBox = ({ placeholder, input, label, meta, type }) => {
    return (
      <div className="m-0">
        <div className="form-check form-check-inline">
          <input
            {...input}
            className="form-check-input"
            type={type}
            onClick={this.checkAction(input)}
          />
          <label
            title=""
            htmlFor="inline-checkbox-1"
            className="form-check-label"
          >
            {label}
          </label>
        </div>
        {this.renderError(meta)}
      </div>
    );
  };

  checkAction = ({ value, name }) => {
    if (name === "copyDetails") {
      if (value) {
        this.props.change("booker_title", this.props.title_1);
        this.props.change("booker_firstName", this.props.firstName_1);
        this.props.change("booker_lastName", this.props.lastName_1);
      }
    }
  };

  renderSelect = ({
    placeholder,
    asyncValidating,
    input,
    label,
    meta,
    children,
  }) => {
    const className = `form-group `;
    return (
      <div className={className}>
        {label && <label>{label}</label>}
        <select {...input} className="form-control">
          {children}
        </select>
        {this.renderError(meta)}
      </div>
    );
  };

  renderRadio = ({
    placeholder,
    asyncValidating,
    input,
    label,
    meta,
    children,
  }) => {
    const className = `form-group col-md-4`;
    const values = children;
    return (
      <>
        <label className="form-label">{label}</label>
        <div className="mb-3">
          {values.map((value, num) => (
            <div className="small form-check form-check-inline" key={num + 1}>
              <input
                {...input}
                type="radio"
                name="preferredContact"
                id={`inline-radio-${num + 1}`}
                className="form-check-input"
                value={value.props.label}
              />
              <label
                title=""
                htmlFor={`inline-radio-${num + 1}`}
                className="form-check-label"
              >
                {value.props.label}
              </label>
            </div>
          ))}
          {this.renderError(meta)}
        </div>
      </>
    );
  };

  onSubmit = async (formValues) => {
    ReactGA.event({
      category: "BOOKING",
      action: `booking for ${formValues.package}`,
      label: "NEW BOOKING",
    });

    this.props.bkgForm(formValues);
  };

  handleCancel = () => {
    console.log("clicked cancel");
  };

  generateNumberArray = (lowEnd, highEnd) => {
    let list = [];
    let i = void 0;
    for (i = lowEnd; i <= highEnd; i++) {
      list.push(i);
    }
    return list;
  };

  render() {
    const { handleSubmit, formValues, booking } = this.props;

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const expiryYears = this.generateNumberArray(
      new Date().getFullYear(),
      2031
    );
    const subscribed = this.props.subscribed;
    const upper = (value) => value && value.toUpperCase().trim();
    const lower = (value) => value && value.toLowerCase().trim();
    const capitalize = (value) =>
      value.charAt(0).toUpperCase().trim() + value.slice(1);

    let subscription = (
      <div>
        <label htmlFor="inputEmail" className="sr-only">
          Email address
        </label>
        <input
          type="email"
          id="inputEmailSubscribe"
          className="form-control mb-2"
          placeholder="Email address"
          required
          autoFocus
        />
        <button
          className="text-uppercase btn btn-lg btn-custom btn-block"
          onClick={this.subscribe}
          type="submit"
        >
          Subscribe
        </button>
      </div>
    );

    if (subscribed) {
      subscription = (
        <div className="text-center pb-2">
          <label className="text-primary font-weight-bold">
            Subscription Successful
          </label>
        </div>
      );
    } else {
      subscription = (
        <div>
          <label htmlFor="inputEmail" className="sr-only">
            Email address
          </label>
          <input
            type="email"
            id="inputEmailSubscribe"
            className="form-control mb-2"
            placeholder="Email address"
            required
            autoFocus
          />
          <button
            className="text-uppercase btn btn-lg btn-custom btn-block"
            onClick={this.subscribe}
            type="submit"
          >
            Subscribe
          </button>
        </div>
      );
    }

    let list = [];

    if (!this.state.loading) {
      return (
        <Container>
          <Title title="Booking Details" />
          <p>
            {this.state.payErrors && (
              <h5>
                <strong>
                  Payment Failed, please contact Viva Holidays on 1300 872 835
                  to finalise your booking.
                </strong>
              </h5>
            )}
          </p>
          <p>
            Please check your shopping cart and enter details to complete your
            booking.
          </p>
          <h4 className="bg-viva-red text-uppercase p-3 mb-4">
            Traveller Details
          </h4>
          <form onSubmit={handleSubmit(this.onSubmit)}>
            {this.state.adultTot &&
              [...Array(parseInt(this.state.adultTot)).keys()].map((i) => {
                return (
                  <>
                    <div className="text-uppercase small">
                      <label>{`Traveller Details | Passenger ${i + 1}:`}</label>
                    </div>
                    <Row>
                      <Col xs="12" sm="12" md="2">
                        <Field
                          name={`title_${i + 1}`}
                          component={this.renderSelect}
                        >
                          <option value="0">Title...</option>
                          <option value="Mr">Mr</option>
                          <option value="Mrs">Mrs</option>
                          <option value="Miss">Miss</option>
                          <option value="Mrs">Ms</option>
                        </Field>
                      </Col>
                      <Col xs="12" sm="12" md="5">
                        <Field
                          name={`firstName_${i + 1}`}
                          component={this.renderInput}
                          //label="First Name *"
                          placeholder="First Name *"
                          normalize={capitalize}
                        />
                      </Col>
                      <Col xs="12" sm="12" md="5">
                        <Field
                          name={`lastName_${i + 1}`}
                          component={this.renderInput}
                          //label="Last Name *"
                          placeholder="Last Name *"
                          normalize={capitalize}
                        />
                      </Col>
                    </Row>
                  </>
                );
              })}

            <div className="text-uppercase small d-flex mr-auto">
              <label>Booking Details:</label>
              <div className="ml-2 font-weight-bold">
                <Field
                  component={this.renderCheckBox}
                  label="As per Passenger 1"
                  type="checkbox"
                  name="copyDetails"
                />
              </div>
            </div>

            <Row>
              <Col xs="12" sm="12" md="2">
                <Field name="booker_title" component={this.renderSelect}>
                  <option value="0">Title...</option>
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Miss">Miss</option>
                  <option value="Mrs">Ms</option>
                </Field>
              </Col>
              <Col xs="12" sm="12" md="5">
                <Field
                  name={`booker_firstName`}
                  component={this.renderInput}
                  //label="First Name *"
                  placeholder="First Name *"
                  normalize={capitalize}
                />
              </Col>
              <Col xs="12" sm="12" md="5">
                <Field
                  name={`booker_lastName`}
                  component={this.renderInput}
                  //label="Last Name *"
                  placeholder="Last Name *"
                  normalize={capitalize}
                />
              </Col>
            </Row>

            <Row>
              <Col xs="12" sm="12" md="4">
                <Field name="phoneCode" component={this.renderSelect}>
                  {_.map(phoneCodes(), (code) => {
                    return (
                      <option
                        key={uuid()}
                        value={code.code}
                      >{`${code.name} (${code.code})`}</option>
                    );
                  })}
                </Field>
              </Col>
              <Col xs="12" sm="12" md="8">
                <Field
                  name="phone"
                  component={this.renderInput}
                  placeholder="Contact Number *"
                  type="number"
                />
              </Col>
              <Col xs="12" sm="12" md="12">
                <Field
                  name="email"
                  component={this.renderInput}
                  placeholder="Email Address *"
                  normalize={lower}
                />
              </Col>
            </Row>

            <Row>
              <Col xs="12" sm="12" md="12">
                <Field
                  name="street"
                  component={this.renderInput}
                  placeholder="Street Address"
                  type="text"
                />
              </Col>
              <Col xs="12" sm="12" md="12">
                <Field
                  name="suburb"
                  component={this.renderInput}
                  placeholder="Suburb / Town"
                  type="text"
                />
              </Col>
            </Row>

            <Row>
              <Col xs="12" sm="12" md="12">
                <Field
                  name="state"
                  component={this.renderInput}
                  placeholder="State"
                  type="text"
                />
              </Col>
              <Col xs="12" sm="12" md="12">
                <Field
                  name="postcode"
                  component={this.renderInput}
                  placeholder="Postcode *"
                  type="number"
                />
              </Col>
              <Col xs="12" sm="12" md="12">
                <Field name="country" component={this.renderSelect}>
                  {_.map(phoneCodes(), (code) => {
                    return (
                      <option
                        key={uuid()}
                        value={code.name}
                      >{`${code.name}`}</option>
                    );
                  })}
                </Field>
              </Col>
            </Row>

            <div className="text-uppercase small">Your request/message:</div>
            <Row className="mb-5">
              <Col md="12">
                <Field
                  name="message"
                  component={this.renderTextArea}
                  placeholder="Please provide as much detail as possible so we can make your request."
                />
                <span className="small font-weight-bold">
                  <em>
                    Requests cannot be guaranteed – but the property will do
                    their best to meet your needs.
                  </em>
                </span>
              </Col>
            </Row>

            {/* START OF PAYMENT */}
            <h4 className="bg-viva-red text-uppercase p-3 mb-4">
              Payment Details
            </h4>

            <div className="form-group d-flex justify-content-end m-0 p-0">
              <span className="border">
                {/*<i className="fa fa-cc-visa fa-2x" />&nbsp;
                                <i className="fa fa-cc-mastercard fa-2x" />&nbsp;
                                <i className="fa fa-cc-amex fa-2x" />*/}
                <img src={PmtOptions} alt="logo" />
              </span>
            </div>

            <Row>
              <Col xs="12" sm="12" md="12">
                <Field
                  name="cardName"
                  component={this.renderInput}
                  placeholder="Name on card *"
                  label="Name on card *"
                />
              </Col>
              <Col xs="12" sm="12" md="12">
                <Field
                  name="cardNo"
                  component={this.renderInput}
                  placeholder="Card Number *"
                  label="Card Number *"
                  type="number"
                />
              </Col>
            </Row>

            <Row>
              <Col xs="12" sm="12" md="12">
                <Field
                  name="expMonth"
                  component={this.renderSelect}
                  label="Expiry Month"
                >
                  <option key={uuid()} value="0">
                    Select Month
                  </option>
                  {_.map(monthNames, (val, id) => {
                    let monthNum = id < 9 ? `0${id + 1}` : `${id + 1}`;
                    return (
                      <option key={uuid()} value={monthNum}>
                        {val}
                      </option>
                    );
                  })}
                </Field>
              </Col>
              <Col xs="12" sm="12" md="12">
                <Field
                  name="expYear"
                  component={this.renderSelect}
                  label="Expiry Year"
                >
                  <option key={uuid()} value="0">
                    Select Year
                  </option>
                  {_.map(expiryYears, (val) => {
                    return (
                      <option key={uuid()} value={val}>
                        {val}
                      </option>
                    );
                  })}
                </Field>
              </Col>
              <Col xs="12" sm="12" md="12">
                <Field
                  name="cardCVV"
                  component={this.renderInput}
                  placeholder="CVV *"
                  label="CVV *"
                  type="number"
                />
              </Col>
            </Row>

            <Field
              component={this.renderCheckBox}
              label="I agree to the terms and conditions"
              type="checkbox"
              name="termsCheck"
            />

            {/* <Field
              component={this.renderCheckBox}
              label="I agree to receive marketing and promotional information"
              type="checkbox"
              name="marketingCheck"
            /> */}

            {/* BOOK AND CANCEL BUTTONS */}

            <div className="form-row mt-4 d-flex">
              <button
                className="text-uppercase btn btn-book w-100"
                disabled={this.state.payErrors}
                type="submit"
              >
                Pay Now
              </button>
              {/* <a
                type="button"
                onClick={this.handleCancel}
                className="reset mt-2"
              >
                Cancel
              </a> */}
            </div>
          </form>
        </Container>
      );
    }
    return loader("Redirecting to our Secure Payment Page");
  }
}

const validate = (formValues, props) => {
  const errors = {};
  const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const regexpPostcode = /^(?:(?:[2-8]\d|9[0-7]|0?[28]|0?9(?=09))(?:\d{2}))$/;

  if (!formValues.email || !regexp.test(formValues.email)) {
    errors.email = "Please enter a valid email";
  }

  if (!formValues.postcode || !regexpPostcode.test(formValues.postcode)) {
    errors.postcode = "Please provide a postcode";
  }

  if (!formValues.subject) {
    errors.subject = "Please provide a subject";
  }

  [...Array(parseInt(props.items.totAdults)).keys()].map((id) => {
    // traveller checks
    let title = `title_${id + 1}`;
    let firstName = `firstName_${id + 1}`;
    let lastName = `lastName_${id + 1}`;

    if (!formValues[title]) {
      errors[title] = "Please select a title";
    }
    if (!formValues[firstName]) {
      errors[firstName] = "Please enter a valid first name";
    }
    if (!formValues[lastName]) {
      errors[lastName] = "Please enter a valid last name";
    }
  });

  if (!formValues.firstName) {
    errors.firstName = "Please enter a valid first name";
  }

  if (!formValues.lastName) {
    errors.lastName = "Please enter a valid last name";
  }

  if (formValues.booker_title === 0) {
    errors.booker_title = "Please select a title";
  }

  if (!formValues.booker_firstName) {
    errors.booker_firstName = "Please enter a valid First Name";
  }

  if (!formValues.booker_lastName) {
    errors.booker_lastName = "Please enter a valid Last Name";
  }

  // if (!formValues.contact || !/^([0-9][0-9]{9})$/i.test(formValues.contact)) {
  //   errors.contact = "Invalid phone number, must be 10 digits";
  // }

  if (!formValues.preferredContact) {
    errors.preferredContact = "Please select a preferred method of contact";
  }

  if (!formValues.departCity) {
    errors.departCity = "Please provide a departure city";
  }

  // if (!formValues.phone || !/^([0-9][0-9]{9})$/i.test(formValues.phone)) {
  //   errors.phone = "Invalid phone number, must be at least 10 digits";
  // }

  if (!formValues.phone || formValues.phone.length < 10) {
    errors.phone = "Phone number must be at least 10 digits";
  }

  // if (!formValues.message) {
  //   errors.message = "Please enter a message";
  // }

  if (!formValues.travelDates) {
    errors.travelDates = "Please select your preferred travel dates";
  }

  /// PAYMENT VALIDATION

  if (!formValues.cardName) {
    errors.cardName = "Please provide a valid cardholder name";
  }

  if (!formValues.cardNo || isNaN(formValues.cardNo)) {
    errors.cardNo = "Please provide a valid credit card number";
  } else if (formValues.cardNo.length < 14 || formValues.cardNo.length > 18) {
    errors.cardNo = "Invalid credit card number length";
  } else {
    let cardObj = creditCardType(formValues.cardNo);
    if (cardObj) {
      if (cardObj[0].typeCode !== "XX") {
        //VALID CARD
        cardObj && props.setCardType(cardObj[0].typeCode);
      } else {
        errors.cardNo = "Card type not accepted";
      }
    } else {
      errors.cardNo = "Please provide a valid credit card number";
    }
  }

  if (!formValues.cardCVV) {
    errors.cardCVV = "Please provide a valid cvv";
  }

  if (formValues.expMonth === 0) {
    errors.expMonth = "Please provide a valid expiry month";
  }

  if (formValues.expYear === 0) {
    errors.expYear = "Please provide a valid expiry year";
  }

  if (!formValues.termsCheck) {
    errors.termsCheck = "Please agree to the terms and conditions to continue";
  }

  return errors;
};

const selector = formValueSelector("bookingForm");

const mapStateToProps = (state, ownProps) => {
  const enqTypeValue = selector(state, "enqType");
  const formValues = { enqType: enqTypeValue };
  const title = Parser(ownProps.items.title)
    .trim()
    .replace(/[$!®@%:,'&*-]/g, "");

  return {
    formValues,
    mail: state.mail,
    subscribe: state.subscribe,
    title_1: selector(state, "title_1"),
    firstName_1: selector(state, "firstName_1"),
    lastName_1: selector(state, "lastName_1"),
    initialValues: {
      package: _.truncate(title, { length: 62, separator: " " }), //eway only allows 64 chars for invoice desc
      phoneCode: "+61",
      country: "Australia",
      noNights: ownProps.items.nights,
      email: "",
      enqType: "Airfares & Accommodation",
      noAdult: ownProps.items.totAdults,
      noChild: ownProps.items.totChildren,
      noInfant: 0,
      fromDate: ownProps.items.dateFrom,
      toDate: ownProps.items.dateTo,
      totPrice: ownProps.items.totPrice,
      optCode: ownProps.items.optCode,
      //optCode: "SYDAC188SYDRMC",
      packageId: ownProps.items.packageId,
      campaignId: ownProps.items.campaignId,
      copyDetails: false,
      expYear: 0,
      expMonth: 0,
      booker_title: 0,
    },
  };
};

const EnquiryFormRecaptcha = withGoogleReCaptcha(BookingCheckout);

export default connect(mapStateToProps, {
  savePaymentReply,
})(
  reduxForm({
    form: "bookingForm",
    // initialValues: {
    //   email: "",
    //   subject: {props.title}
    // },
    validate,
    enableReinitialize: true,
    destroyOnUnmount: false,
  })(EnquiryFormRecaptcha)
);
