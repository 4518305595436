import { persist } from "./services/reduxPersist";
import { persistStore } from "redux-persist";
import { createLogger } from "redux-logger";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { reducer as formReducer } from "redux-form";
import bookingReducer from "./reducers/bookingReducer";
import { drupalReducer } from "./reducers";
import reduxThunk from "redux-thunk";
import thunkMiddleware from "redux-thunk";
import { createMigrate } from "redux-persist";
import { migrations } from "./reducers/drupalReducer";

const MIGRATION_DEBUG = false;

// const loggerMiddleware = createLogger();

const keyName =
  process.env.REACT_APP_MODE === "goholsnz" ? "goPersist" : "vivaPersist";

const drupalPersistConfig = {
  key: keyName,
  version: 0,
  migrate: createMigrate(migrations, { debug: MIGRATION_DEBUG }),
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  [keyName]: persist(drupalPersistConfig, drupalReducer),
  form: formReducer,
  bookingStore: bookingReducer,
});

let middleware = [reduxThunk, thunkMiddleware];
if (process.env.NODE_ENV === "development") {
  middleware = [...middleware, createLogger()];
}

const devTools =
  process.env.NODE_ENV === "production"
    ? applyMiddleware(...middleware)
    : composeEnhancers(applyMiddleware(...middleware));

const store = createStore(reducers, devTools);

export const persistor = persistStore(store);

export default store;
