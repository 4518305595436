import React from "react";
import { Link } from "react-router-dom";
import { Card, Button, Row } from "react-bootstrap";
import { daysdifference } from "../helpers/helpFunction";
import { renderLocations } from "../helpers";
import Parser from "html-react-parser";
import _ from "lodash";
import Ribbon from "./Ribbon";
import moment from "moment";

const CartCard = (props) => {
  const { pkg, basePath, path, type, image, noLoc, items } = props;

  let urlPath = basePath ? `${basePath}/${path}` : path;

  let priceShow = pkg.field_hide_price_banner == "0" ? true : false;
  let price = pkg.field_package_price
    ? pkg.field_package_price
    : pkg.field_campaign_price;

  // country.title.replace(/\s+/g, "-").toLowerCase()

  let expire = daysdifference(
    new Date(),
    pkg.field_on_sale_until === ""
      ? new Date()
      : new Date(pkg.field_on_sale_until)
  );
  let ended,
    textBtn = "";

  let destinations = [];
  destinations = pkg.field__campaign_destination;
  //console.log(pkg.field_campaign_price);

  let title = pkg.title.replace(/-/g, "<br />");

  return (
    <div className="row cart-content border-top border-light pt-3 pb-2">
      <div className="col-md-12">
        {/* <div className="remove-from-cart text-right">
          <a className="reset">Remove</a>
        </div> */}
        <Link to={items.path}>
          <Card.Img className="rounded-0 pb-1" variant="top" src={image} />
        </Link>
      </div>
      <div className="col-md-12">
        <h6 className="text-capitalize font-weight-bold">{Parser(title)}</h6>
        {/* <Link
        to={{
          pathname: urlPath,
          state: { params: { pkgid: pkg.nid } },
        }}
        className={expire > 0 ? "" : "opacity-8"}
      >
        
        {priceShow && ( */}

        {!items.giftCard && (
          <div className="border-bottom border-light pb-2">
            {/* {`${pkg.field_savings}`} <br /> */}
            <h6 className="p-0 m-0">Adults: {items.totAdults}</h6>

            <h6 className="p-0 m-0">Total Nights: {items.nights}</h6>
            <h6 className="p-0 m-0">
              From: {moment(items.dateFrom).format("DD-MM-YYYY")}
            </h6>
            <h6 className="p-0 m-0">
              To: {moment(items.dateTo).format("DD-MM-YYYY")}
            </h6>

            {/* <h6 className="p-0 m-0">Per Person: {`${price}`}</h6> */}
          </div>
        )}
        {/* )}
      </Link> */}
        {!noLoc && (
          <div className="">
            <div className="campaign-destination mt-1">
              {renderLocations(pkg)}
              {/* <Link to={`/destinations/AR`}>
                  <i className="fas fa-map-marker-alt pr-1"></i>
                  {pkg.field__campaign_destination}
                </Link> */}
            </div>
            {/* Urgency User Message - prompts action by the user */}
            {/* <span className="card-urgency small pl-2">{ended}</span> */}
          </div>
        )}
      </div>

      {pkg.field_top_pick === 1 && (
        <div className="top-pick-banner text-uppercase">Top Pick</div>
      )}

      {/* <div className="card-book-now">{textBtn}</div> */}
      {pkg.field_uj_coming_soon == 1 && (
        <Ribbon classType="coming-soon-landing" title="Coming Soon" />
      )}
    </div>
  );
};

CartCard.defaultProps = {
  type: "packages",
};

export default CartCard;
