import React, { useState } from "react";
import { Navbar, Nav, Form, NavDropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
//import logo from "../../assets/img/viva_vertical_logo.png";
import logowhite from "../../assets/img/VivaHolidays_Vertical_White_Logo_2019_h60px.png";
import history from "../../history";

import SearchForm from "../../elements/SearchForm";
import { default as BASE } from "../../config";
import { phoneBlock } from "../../helpers/phoneBlock";

const Navigation = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [expanded, setExpanded] = useState(false);
  const [activeClass, setActiveClass] = useState(false);
  const [activeClass1, setActiveClass1] = useState(false);
  const [activeClass2, setActiveClass2] = useState(false);
  const fileName = BASE.CONFIG.SECONDARY_LOGO_FILENAME;

  history.listen((location, action) => {
    if (location.pathname.includes("/deals")) {
      setActiveClass(true);
      setActiveClass1(false);
      setActiveClass2(false);
    }
    else if (location.pathname === "/about-us" || location.pathname === "/book-with-confidence") {
      setActiveClass1(true);
      setActiveClass(false);
      setActiveClass2(false);
    }
    else if (location.pathname.includes("/groups")) {
      setActiveClass(false);
      setActiveClass1(false);
      setActiveClass2(true);
    }
    else
    {
      setActiveClass(false);
      setActiveClass1(false);
      setActiveClass2(false);
    }
  });

  const setEvents = (e) => {
    setExpanded(false);
  };

  return (
    <>
      <div className="main-nav sticky-top p-0 d-md-flex d-lg-flex">
        <Navbar
          className="mr-auto col-lg-10 navbar-dark"
          collapseOnSelect
          expand="xl"
          expanded={expanded}
        >
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={() => setExpanded(expanded ? false : "expanded")}
          />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="navbar navbar-expand-xl navbar-menu-mob">
              <NavLink
                exact
                className="nav-link"
                to="/"
                onClick={(e) => setEvents(e)}
              >
                Home
              </NavLink>
              {/* <NavLink className="nav-link" to="/exclusive-deals">
                Exclusive Deals
              </NavLink> */}
              <NavLink
                className="nav-link"
                to="/packages"
                onClick={(e) => setEvents(e)}
              >
                Packages
              </NavLink>

              {/*<NavDropdown
                title="Deals"
                id="basic-nav-dropdown"
                className={activeClass ? "active" : ""}
              >
                <NavDropdown.Item
                  as={NavLink}
                  exact
                  to="/deals"
                  onClick={(e) => setEvents(e)}
                >
                  All Deals
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={NavLink}
                  exact
                  to={{ pathname: "/deals/exclusives" }}
                  onClick={(e) => setEvents(e)}
                >
                  Exclusives
                </NavDropdown.Item>*/}

                {/* <NavDropdown.Item
                  as={NavLink}
                  exact
                  to={{ pathname: "/deals/luxury" }}
                  onClick={(e) => setEvents(e)}
                >
                  Luxury
                </NavDropdown.Item> */}

                {/*
                <NavDropdown.Item
                  as={NavLink}
                  exact
                  to={{ pathname: "/deals/self-drive" }}
                  onClick={(e) => setEvents(e)}
                >
                  Self Drive
                </NavDropdown.Item>

                <NavDropdown.Item
                  as={NavLink}
                  exact
                  to={{ pathname: "/deals/rail-holidays" }}
                  onClick={(e) => setEvents(e)}
                >
                  Rail Holidays
                </NavDropdown.Item> */}

                {/*}<NavDropdown.Item
                  as={NavLink}
                  exact
                  to={{ pathname: "/deals/glamping" }}
                  onClick={(e) => setEvents(e)}
                >
                  Glamping
                </NavDropdown.Item>

                <NavDropdown.Item
                  as={NavLink}
                  exact
                  to={{ pathname: "/deals/events" }}
                  onClick={(e) => setEvents(e)}
                >
                  Events
                </NavDropdown.Item>*/}

                {/* <NavDropdown.Item
                  as={NavLink}
                  exact
                  to={{ pathname: "/deals/family" }}
                  onClick={(e) => setEvents(e)}
                >
                  Family
                </NavDropdown.Item>
              </NavDropdown>*/}


              {/* <NavLink className="nav-link" to="/group-tours">
                Group Tours
              </NavLink> */}

              {/* <NavDropdown
                title="Groups"
                id="basic-nav-dropdown"
                className={activeClass2 ? "active" : ""}
              >
                <NavDropdown.Item
                  as={NavLink}
                  exact
                  to={{ pathname: "/groups/rail" }}
                  onClick={(e) => setEvents(e)}
                >
                  Rail
                </NavDropdown.Item>

                <NavDropdown.Item
                  as={NavLink}
                  exact
                  to={{ pathname: "/groups/cruise" }}
                  onClick={(e) => setEvents(e)}
                >
                  Cruise
                </NavDropdown.Item>

              </NavDropdown>*/}

              <NavLink
                className="nav-link"
                to="/escorted-tours"
                onClick={(e) => setEvents(e)}
              >
                Escorted Tours
              </NavLink>

              <NavLink
                className="nav-link"
                to="/ultimate-journeys"
                onClick={(e) => setEvents(e)}
              >
                Ultimate Journeys
              </NavLink>

              {/* <NavLink className="nav-link" to="/events">
                Events
              </NavLink> */}
              <NavLink
                className="nav-link"
                to="/gift-vouchers"
                onClick={(e) => setEvents(e)}
              >
                Gift Cards
              </NavLink>
              <NavLink
                className="nav-link"
                to="/destinations"
                onClick={(e) => setEvents(e)}
              >
                Destinations
              </NavLink>
              {/* <NavLink className="nav-link" to="/news">
                News
              </NavLink> */}
              <NavLink
                className="nav-link"
                to="/brochures"
                onClick={(e) => setEvents(e)}
              >
                Brochures
              </NavLink>

              {/*<NavLink
                className="nav-link"
                to="/about-us"
                onClick={(e) => setEvents(e)}
              >
                AboutXXX
              </NavLink>*/}

              <NavDropdown
                title="About"
                id="basic-nav-dropdown"
                className={activeClass1 ? "active" : ""}
              >
                <NavDropdown.Item
                  as={NavLink}
                  exact
                  to="/about-us"
                  onClick={(e) => setEvents(e)}
                >
                  About Us
                </NavDropdown.Item>

                <NavDropdown.Item
                  as={NavLink}
                  exact
                  to={{ pathname: "/book-with-confidence" }}
                  onClick={(e) => setEvents(e)}
                >
                  Book With Confidence
                </NavDropdown.Item>

              </NavDropdown>


              <NavLink
                className="nav-link"
                to="/contact"
                onClick={(e) => setEvents(e)}
              >
                Contact
              </NavLink>

              {/* Search and cart for smaller devices only - hidden on larger devices */}
              <Form className="d-flex border-top mt-3 p-3 d-none d-md-block d-xl-none">
                {/* <i
                className="fas fa-shopping-cart fa-1x p-2"
                onClick={() => setModalShow(true)}
              ></i> */}
                <i
                  className="fas fa-search fa-2x"
                  onClick={() => setModalShow(true)}
                ></i>
              </Form>
            </Nav>
          </Navbar.Collapse>

          <Form className="d-flex">
            {/* <i
              className="fas fa-shopping-cart fa-1x p-2"
              onClick={() => setModalShow(true)}
            ></i> */}
            <i
              className="fas fa-search fa-1x p-2 d-none d-xl-block"
              onClick={() => setModalShow(true)}
            ></i>
          </Form>
        </Navbar>

        {/* Secondary logo for smaller devices */}
        <div className="secondary-logo d-sm-block d-md-none">
          <Link to="/">
            {" "}
            <img
              className="img-fluid m-md-auto p-2"
              src={logowhite}
              // src={require(`../../assets/img/${fileName}`)}
              // alt="logo"
            />
          </Link>
        </div>
        {/* Secondary logo for smaller devices */}

        {phoneBlock()}
      </div>
      <SearchForm show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default Navigation;
