import veraAPI from "../apis/veraApi";
import _ from "lodash";

export async function cancelBooking(tpRef) {
  let response = "";

  response = await veraAPI
    .post("book/cancelBooking", {
      providers: ["tp"],
      agent: process.env.REACT_APP_TP_AGENT_VIVA_AU,
      password: process.env.REACT_APP_TP_PASS_VIVA_AU,
      bookingReference: tpRef,
    })
    .catch((error) => {
      //response = error;
      console.log(error);
    });

  return response;
}
