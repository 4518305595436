import React from "react";
import { Link } from "react-router-dom";
import { Card, Button, Row } from "react-bootstrap";
import { daysdifference } from "../helpers/helpFunction";
import { renderLocations } from "../helpers";
import Parser from "html-react-parser";
import _ from "lodash";
import Ribbon from "./Ribbon";

const DealCard = (props) => {
  const {
    pkg,
    basePath,
    path,
    type,
    image,
    noLoc,
    campaign,
    campaignPath,
  } = props;
  let urlPath = basePath ? `${basePath}/${path}` : path;

  let priceShow = pkg.field_hide_price_banner == "0" ? true : false;
  let price = pkg.field_package_price
    ? pkg.field_package_price
    : pkg.field_campaign_price;

  let dealLoc = pkg.field_local_location ? true : false;

  // country.title.replace(/\s+/g, "-").toLowerCase()

  let expire = daysdifference(
    new Date(),
    pkg.field_on_sale_until === ""
      ? new Date()
      : new Date(pkg.field_on_sale_until)
  );
  let ended,
    textBtn = "";

  let destinations = [];
  destinations = pkg.field__campaign_destination;
  //console.log(pkg.field_campaign_price);

  const title = pkg.field_vc_deal_title ? pkg.field_vc_deal_title : pkg.title;

  return (
    <Card className="deal-card mb-4">
      {priceShow && (
        <div className="priceDuration d-flex">
          <small className="mr-auto text-right pr-5">
            {`${pkg.field_savings}`} <br />
            <span>from:</span>
          </small>
          <h2 className="p-0 m-0">{`${price}`}</h2>
        </div>
      )}
      <Link
        to={{
          pathname: urlPath,
          state: { params: { pkgid: pkg.nid } },
        }}
        className={expire > 0 ? "" : "opacity-8"}
      >
        <Card.Img variant="top" className="getaway-image p-2" src={image} />
      </Link>
      {pkg.field_top_pick === 1 && (
        <div className="top-pick-banner text-uppercase">Top Pick</div>
      )}
      <div>
        <Card.Title className="card-header rounded-0">
          <h4 className="my-0 text-capitalize">{Parser(title)}</h4>

          <h6 className="my-0 text-capitalize">
            {campaign && Parser(campaign)}
          </h6>

          {/* Deal Locations */}
          {dealLoc && (
            <div className="d-flex">
              <div className="deal-destination mt-1 font-weight-bold text-uppercase">
                <i className="fas fa-map-marker-alt pr-1"></i>
                <strong>{pkg.field_local_location}</strong>
              </div>
            </div>
          )}

          {/* Package Locations */}
          {!noLoc && (
            <div className="d-flex">
              <div className="campaign-destination mt-1">
                {renderLocations(pkg)}
                {/* <Link to={`/destinations/AR`}>
                  <i className="fas fa-map-marker-alt pr-1"></i>
                  {pkg.field__campaign_destination}
                </Link> */}
              </div>
              {/* Urgency User Message - prompts action by the user */}
              {/* <span className="card-urgency small pl-2">{ended}</span> */}
            </div>
          )}
        </Card.Title>
      </div>
      {/* <div className="card-book-now">{textBtn}</div> */}
      {pkg.field_uj_coming_soon == 1 && (
        <Ribbon classType="coming-soon-landing" title="Coming Soon" />
      )}
    </Card>
  );
};

DealCard.defaultProps = {
  type: "packages",
  dealLoc: false,
};

export default DealCard;
