import React, { useEffect, useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row } from "react-bootstrap";
import drupal from "../../apis/drupal";
import { renderShoppingCart } from "../../helpers";
import _ from "lodash";

const BookingCheckoutCart = ({ items }) => {
  const dispatch = useDispatch();
  const [pkgList, setPkgList] = useState([]);

  useEffect(() => {
    // if (!props.location.booking) {
    //   history.push("/");
    fetchData();

    // }
  }, [setPkgList]);

  const fetchData = async () => {
    const setBASE =
      process.env.REACT_APP_MODE === "goholsnz" ? "/nz/deal/" : "/au/deal/";

    let res = await drupal.get(`${setBASE}${items.packageId}`);

    setPkgList(res.data);
  };

  const renderCartList = (view, premFlag, pgPath) => {
    return (
      view.length > 0 && (
        <Row>
          <div className="viva-cart p-1">
            <h3 className="text-uppercase pl-3 p-2 mb-0" title="Deals">
              Booking Summary
            </h3>
            {renderShoppingCart(items, view, premFlag, pgPath)}
            <div className="total-purchase-price text-uppercase d-flex justify-content-center">
              <h4>
                Total Price:
                <span className="text-viva-red pl-2 font-weight-bold">
                  ${items.totPrice}.00
                </span>
              </h4>
            </div>
          </div>
        </Row>
      )
    );
  };

  if (!_.isEmpty(pkgList)) {
    return (
      <Container className="viva-cart-container">
        {" "}
        {renderCartList(pkgList, false, null)}
      </Container>
    );
  } else {
    return <></>;
  }
  // return <Container></Container>;
};
export default BookingCheckoutCart;
