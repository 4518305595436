import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";

import {
  PackageListComponent,
  PackagePageComponent,
  DealPageComponent,
  DealListComponent,
  VoucherComponent,
  PreviewComponent,
  PreviewPackageComponent,
  PreviewDealComponent,
} from "../../../seo";

const PackageRoutes = () => {
  return (
    <>
      <Route
        exact
        path="/deals"
        render={(props) => <DealListComponent {...props} title={"Deals"} />}
      />
      <Route exact path="/deals/:style" component={DealListComponent} />
      <Route exact path="/deals/:deal/:id" component={DealPageComponent} />
      <Route
        exact
        path="/packages"
        render={(props) => (
          <PackageListComponent {...props} title={"Packages"} />
        )}
      />
      <Route exact path="/packages/:title" component={PackagePageComponent} />
      <Route
        exact
        path="/packages/:title/:deal/:id"
        component={DealPageComponent}
      />
      <Route
        exact
        path="/events"
        render={(props) => <PackageListComponent {...props} title={"Events"} />}
      />
      <Route exact path="/events/:title" component={PackagePageComponent} />
      <Route
        exact
        path="/events/:title/:deal/:id"
        component={DealPageComponent}
      />
      <Route
        exact
        path="/group-tours"
        render={(props) => (
          <PackageListComponent {...props} title={"Group Tours"} />
        )}
      />
      <Route
        exact
        path="/group-tours/:title"
        component={PackagePageComponent}
      />
      <Route
        exact
        path="/group-tours/:title/:deal/:id"
        component={DealPageComponent}
      />
      <Route
        exact
        path="/exclusive-deals"
        render={(props) => (
          <PackageListComponent {...props} title={"Exclusive Deals"} />
        )}
      />
      <Route
        exact
        path="/exclusive-deals/:title"
        component={PackagePageComponent}
      />
      <Route
        exact
        path="/exclusive-deals/:title/:deal/:id"
        component={DealPageComponent}
      />
      <Route path="/gift-vouchers" exact component={VoucherComponent} />
      <Route
        exact
        path="/preview/:id"
        render={(props) => <PreviewComponent {...props} title={"Preview"} />}
      />
      <Route
        exact
        path="/preview/packages/:title"
        render={(props) => (
          <PreviewPackageComponent {...props} title={"Preview"} />
        )}
      />
      <Route
        exact
        path="/preview/packages/:title/:deal/:id"
        component={PreviewDealComponent}
      />
    </>
  );
};

export default PackageRoutes;
