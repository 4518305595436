import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";

import {
  EscortedTourListComponent,
  EscortedTourPageComponent
} from "../../../seo";

const EscortedToursRoutes = () => {
  return (
    <>
      <Route
        exact
        path="/escorted-tours"
        render={(props) => (
          <EscortedTourListComponent {...props} title={"Escorted Tours"} />
        )}
      />
      <Route exact path="/escorted-tours/:title" component={EscortedTourPageComponent} />
    </>
  );
};

export default EscortedToursRoutes;
