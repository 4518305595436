import React, { useState, useEffect, useContext } from "react";
import { Container, Row } from "react-bootstrap";
import { v1 as uuid } from "uuid";
import PersistedContext from "../../context/persistedContext";
import DealCard from "../../elements/DealCard";
import Title from "../../elements/Title";
import _ from "lodash";
import Filters from "../../elements/PackageFilters";

const PackageList = (props) => {
  const { packages } = useContext(PersistedContext);
  const [allPackages, setAllPackages] = useState({});

  const [srchTitle, setSrchTitle] = useState(null);
  const [srchDest, setSrchDest] = useState(null);
  const [srchPkg, setSrchPkg] = useState(null);
  const [errorFlag, setErrorFlag] = useState(false);

  const [filteredPackages, setFilteredPackages] = useState({});

  useEffect(() => {
    applyFilters();
  }, [srchTitle, srchDest, srchPkg]);

  useEffect(() => {
    setData();
  }, [packages]);

  const applyFilters = () => {
    let tempPkgList = allPackages;

    //title filter
    if (srchTitle) {
      //console.log(srchTitle);
      tempPkgList = _.filter(tempPkgList, (pkg) =>
        pkg.title.toLowerCase().includes(srchTitle.toLowerCase())
      );
    }

    //dest filter
    if (srchDest && isNaN(srchDest)) {
      //console.log(srchDest);
      tempPkgList = _.filter(tempPkgList, (pkg) =>
        pkg.field__campaign_destination.includes(srchDest)
      );
    }

    //package type filter
    if (srchPkg && isNaN(srchPkg)) {
      //console.log(srchDest);
      tempPkgList = _.filter(tempPkgList, (pkg) =>
        pkg.field_consumer_deal_types.toLowerCase().includes(srchPkg)
      );
    }

    if (!srchTitle && !srchDest && !srchPkg) {
      //no filter applied
      setFilteredPackages(allPackages);
      setErrorFlag(false);
    } else if (_.isEmpty(tempPkgList)) {
      //filter returned no results
      setFilteredPackages(allPackages);
      setErrorFlag(true);
    } else {
      //filter applied and results found
      setErrorFlag(false);
      setFilteredPackages(tempPkgList);
    }
  };

  const resetFilters = () => {
    setSrchDest(null);
    setSrchTitle(null);
    setSrchPkg(null);
  };

  // const destinationSelect = e => {
  //   let choice = e.target.value;
  //   setSrchDest(choice);

  //   if (choice == 0) {
  //     setFilteredPackages(allPackages);
  //   } else {
  //     setFilteredPackages(
  //       _.filter(allPackages, pkg =>
  //         pkg.field__campaign_destination.includes(choice)
  //       )
  //     );
  //   }
  // };

  // const titleSearch = e => {
  //   let choice = e.target.value;
  //   setSrchTitle(choice);

  // if (choice == 0) {
  //   setFilteredPackages(allPackages);
  // } else {
  //   setFilteredPackages(
  //     _.filter(allPackages, pkg => pkg.title.toLowerCase().includes(choice))
  //   );
  // }
  // };

  const setData = () => {
    const filteredList = applyPageFilter();
    setAllPackages(filteredList);
    setFilteredPackages(filteredList);
  };

  const applyPageFilter = () => {
    // switch (props.title) {
    //   case "Packages":
    //     return _.filter(packages, item => {
    //       return (
    //         item.field_deal_type !== "Events" &&
    //         item.field_deal_type !== "Group Tours"
    //       );
    //     });
    //   case "Events":
    //     return _.filter(packages, item => {
    //       return item.field_deal_type === "Events";
    //     });
    //   case "Exclusive Deals":
    //     return _.filter(packages, "field_exclusive_deals", 1);
    //   case "Group Tours":
    //     return _.filter(packages, item => {
    //       return item.field_deal_type === "Group Tours";
    //     });
    //   default:
    //     return packages;
    // }

    if (props.search) {
      return packages;
    } else if (props.location.pathname.includes("exclusive-deals")) {
      return _.filter(packages, "field_exclusive_deals", 1);
    } else {
      return _.filter(packages, (item) => {
        return item.field_package_category === props.location.pathname;
      });
    }
  };

  const renderList = () => {
    // let data = _.isEmpty(packages) ? props.packages : packages;
    return _.map(filteredPackages, (deal) => {
      return deal.title == "Sunlover Escapes" ? null : (
        <div className="col-xs-12 col-sm-12 col-md-6" key={uuid()}>
          <DealCard
            pkg={deal}
            image={deal.field_main_image}
            path={deal.field_path}
            basePath={deal.field_package_category}
          />
        </div>
      );
    });
  };

  return (
    <Container>
      <Title title={props.title} />
      {packages && (
        <Filters
          content={allPackages}
          setSrchDest={setSrchDest}
          setSrchTitle={setSrchTitle}
          setSrchPkg={setSrchPkg}
          resetFilters={resetFilters}
          errorFlag={errorFlag}
        />
      )}
      <Row>{packages && renderList()}</Row>
    </Container>
  );
};

export default PackageList;
