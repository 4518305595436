import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";

import {
  UltimateJourneyListComponent,
  UltimateJourneyPageComponent
} from "../../../seo";

const UltimateJourneyRoutes = () => {
  return (
    <>
      <Route
        exact
        path="/ultimate-journeys"
        render={(props) => (
          <UltimateJourneyListComponent {...props} title={"Ultimate Journeys"} />
        )}
      />
      <Route exact path="/ultimate-journeys/:title" component={UltimateJourneyPageComponent} />
    </>
  );
};

export default UltimateJourneyRoutes;
