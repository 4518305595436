import React from "react";
import { Modal, Container, Button } from "react-bootstrap";
import PackageList from "../components/packages/PackageList";
import Title from "../elements/Title";

const SearchForm = props => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable={true}
    >
      <Modal.Header className="pt-5 pl-5 pr-5" closeButton>
        <Title title="Search Packages" />
      </Modal.Header>
      <Modal.Body className="container col-sm-12 col-md-12 col-lg-11">
        <PackageList search="true" />
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
};

export default SearchForm;
