import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { default as BASE } from "../../config";

//import logo from "../../assets/img/viva_vertical_logo.png";

const Header = () => {
  //const logo = "../../assets/img/viva_vertical_logo.png";
  const fileName = BASE.CONFIG.LOGO_FILENAME;

  return (
    <header className="main-header d-none d-md-block">
      <div className="container">
        <Link to="/">
          {" "}
          <img
            className="img-fluid main-logo m-md-auto p-2"
            src={require(`../../assets/img/${fileName}`)}
            alt="logo"
          />
        </Link>
      </div>
    </header>
  );
};

export default Header;
