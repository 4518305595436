//import vera from "../apis/vera";
import vera from "../apis/vera";

export async function sendPayment(formValues, booking) {
  let response = "";

  response =
    booking &&
    (await vera
      .post("/payman/api/pay/direct", {
        payAmt: `${formValues.totPrice}00`,
        payCny: "AUD",
        cardNumber: formValues.cardNo,
        cardName: formValues.cardName,
        expMonth: formValues.expMonth,
        expYear: formValues.expYear,
        ccv: formValues.cardCVV,
        method: "ProcessPayment",
        transactionType: "Purchase",
        invoiceReference: booking.ref,
        invoiceDescription: formValues.package,
        Title: formValues.booker_title,
        FirstName: formValues.booker_firstName,
        LastName: formValues.booker_lastName,
        CompanyName: "",
        Street1: formValues.street,
        Street2: "",
        City: formValues.suburb,
        State: formValues.state,
        PostalCode: formValues.postcode,
        Country: "au",
        Phone: `${formValues.phoneCode}${formValues.phone}`,
        Mobile: `${formValues.phoneCode}${formValues.phone}`,
        Email: formValues.email,
      })
      .catch((error) => {
        response = error;
      }));

  return response;
}
