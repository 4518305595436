import {
  BOOKING_STORE_STATUS,
  BOOKING_AVAILABILITY_CHECK_STATUS,
  TOGGLE_MODAL,
  TOGGLE_CHECK_AVAIL_BUTTON,
  BOOKING_FETCH_OPTION_STATUS,
  SEND_EMAIL_STATUS,
} from "../actions/types";

const initialState = {
  error: null,
  isDirty: false,
  loading: false,
  redirect: false,
  paymentModalStatus: false,
  checkAvailStatus: true,
  multiAvail: [],
  blockedDates: [],
  datesLoading: true,
  emailSent: false,
  currentbooking: {
    from: "",
    to: "",
    numberOfAdults: 0,
    numberOfChildren: 0,
    totalPrice: 0,
    passengers: [],
    addressLine: "",
    suburb: "",
    state: "",
    postcode: "",
    phoneNo: "",
    email: "",
    getOpt: "",
    bookingInfo: "",
    available: false,
  },
  passenger: {
    uuid: "",
    title: "",
    firstName: "",
    lastName: "",
    adult: false,
    child: false,
    age: 0,
  },
};

function bookingReducer(state = initialState, action) {
  //NEED TO UPDATE STATE OF EACH PASSENGER & CURRENT BOOKING OBJECT
  switch (action.type) {
    case BOOKING_STORE_STATUS.STORE_TRAVEL_RANGE_FROM:
      const from = JSON.parse(JSON.stringify(action.payload));
      // const to = JSON.parse(JSON.stringify(action.payload.to));

      return {
        ...state,
        isDirty: true,
        loading: false,
        error: null,
        currentbooking: {
          ...state.currentbooking,
          from: from,
        },
      };

    case BOOKING_STORE_STATUS.STORE_TRAVEL_RANGE_TO:
      const to = JSON.parse(JSON.stringify(action.payload));

      return {
        ...state,
        isDirty: true,
        loading: false,
        error: null,
        currentbooking: {
          ...state.currentbooking,
          to: to,
        },
      };

    case BOOKING_STORE_STATUS.STORE_TRAVELLERS_ADULT:
      const adult = JSON.parse(JSON.stringify(action.payload));

      return {
        ...state,
        isDirty: true,
        loading: false,
        error: null,
        currentbooking: {
          ...state.currentbooking,
          numberOfAdults: adult,
        },
      };

    case BOOKING_STORE_STATUS.STORE_TRAVELLERS_CHILD:
      const child = JSON.parse(JSON.stringify(action.payload));

      return {
        ...state,
        isDirty: true,
        loading: false,
        error: null,
        currentbooking: {
          ...state.currentbooking,
          numberOfChildren: child,
        },
      };

    case BOOKING_STORE_STATUS.STORE_PASSENGERS:
      const passengers = JSON.parse(JSON.stringify(action.payload));

      return {
        ...state,
        isDirty: true,
        loading: false,
        error: null,
        currentbooking: {
          ...state.currentbooking,
          passengers: passengers,
        },
      };

    case BOOKING_STORE_STATUS.CLEAR_PASSENGERS:
      return {
        ...state,
        passenger: {
          uuid: "",
          title: "",
          firstName: "",
          lastName: "",
          adult: false,
          child: false,
          age: 0,
        },
        currentbooking: {
          ...state.currentbooking,
          passengers: [],
        },
      };

    case BOOKING_STORE_STATUS.STORE_ADDRESS:
      const addressLine = JSON.parse(
        JSON.stringify(action.payload.addressLine)
      );
      const suburb = JSON.parse(JSON.stringify(action.payload.suburb));
      const addstate = JSON.parse(JSON.stringify(action.payload.addstate));
      const postcode = JSON.parse(JSON.stringify(action.payload.postcode));
      const phoneNo = JSON.parse(JSON.stringify(action.payload.phoneNo));
      const email = JSON.parse(JSON.stringify(action.payload.email));

      return {
        ...state,
        isDirty: true,
        loading: false,
        datesLoading: true,
        error: null,
        currentbooking: {
          ...state.currentbooking,
          addressLine: addressLine,
          suburb: suburb,
          state: addstate,
          postcode: postcode,
          phoneNo: phoneNo,
          email: email,
        },
      };

    case TOGGLE_MODAL.PAYMENT:
      return {
        ...state,
        paymentModalStatus: !state.paymentModalStatus,
      };

    case BOOKING_AVAILABILITY_CHECK_STATUS.MULTI_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case BOOKING_AVAILABILITY_CHECK_STATUS.MULTI_SUCCESS:
      const avail = JSON.parse(JSON.stringify(action.payload));
      return {
        ...state,
        loading: true,
        multiAvail: avail,
        currentbooking: {
          ...state.currentbooking,
          available: true,
        },
      };

    case BOOKING_AVAILABILITY_CHECK_STATUS.BLOCKED_DATES:
      const blocked_dates = action.payload;

      console.log(blocked_dates);
      return {
        ...state,
        loading: false,
        datesLoading: false,
        blockedDates: blocked_dates,
        currentbooking: {
          ...state.currentbooking,
          available: true,
        },
      };

    case BOOKING_AVAILABILITY_CHECK_STATUS.MULTI_FAILURE:
      return {
        ...state,
        loading: false,
        datesLoading: false,
        error: action.payload.error,
        currentbooking: {
          ...state.currentbooking,
          available: false,
        },
      };

    case BOOKING_AVAILABILITY_CHECK_STATUS.BEGIN:
      return {
        ...state,
        loading: true,
      };

    case BOOKING_AVAILABILITY_CHECK_STATUS.SUCCESS:
      return {
        ...state,
        loading: false,
        currentbooking: {
          ...state.currentbooking,
          available: true,
        },
      };

    case BOOKING_AVAILABILITY_CHECK_STATUS.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        currentbooking: {
          ...state.currentbooking,
          available: false,
        },
      };

    case BOOKING_FETCH_OPTION_STATUS.BEGIN:
      return {
        ...state,
        loading: true,
      };

    case BOOKING_FETCH_OPTION_STATUS.SUCCESS:
      return {
        ...state,
        loading: false,
        currentbooking: {
          ...state.currentbooking,
          available: true,
        },
      };

    case BOOKING_FETCH_OPTION_STATUS.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        currentbooking: {
          ...state.currentbooking,
          available: false,
        },
      };

    case SEND_EMAIL_STATUS.BEGIN:
      return {
        ...state,
        loading: true,
      };

    case SEND_EMAIL_STATUS.SUCCESS:
      return {
        ...state,
        loading: false,
        emailSent: true,
      };

    case SEND_EMAIL_STATUS.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        emailSent: false,
      };

    case TOGGLE_CHECK_AVAIL_BUTTON.TOGGLE:
      const toggle = JSON.parse(JSON.stringify(action.payload));
      return {
        ...state,
        checkAvailStatus: toggle,
      };

    case BOOKING_STORE_STATUS.STORE_PRICE:
      console.log(action.payload);
      const price = JSON.parse(JSON.stringify(action.payload));
      return {
        ...state,
        currentbooking: {
          ...state.currentbooking,
          totalPrice: price,
        },
      };

    case BOOKING_STORE_STATUS.STORE_GET_OPT_RESPONSE:
      const opt = JSON.parse(JSON.stringify(action.payload));
      return {
        ...state,
        currentbooking: {
          ...state.currentbooking,
          getOpt: opt,
        },
      };

    case BOOKING_STORE_STATUS.STORE_BOOKING_INFO_RESPONSE:
      const info = JSON.parse(JSON.stringify(action.payload));
      return {
        ...state,
        currentbooking: {
          ...state.currentbooking,
          bookingInfo: info,
        },
      };

    case BOOKING_STORE_STATUS.STORE_BOOKING_REDIRECT:
      const redirect = JSON.parse(JSON.stringify(action.payload));

      return {
        ...state,
        redirect: redirect,
      };

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }

  return state;
}

export default bookingReducer;
