import React from "react";
import { Link } from "react-router-dom";
import Parser from "html-react-parser";

const Title = ({ title, pkg }) => {
  return (
    <>
      <div className="custom-page-title">
        <h2>{title}</h2>
        {pkg && (
          <Link to={`${pkg.field_package_category}/${pkg.field_path}`}>
            <h5 className="pl-4 text-viva-red">{Parser(pkg.title)}</h5>
          </Link>
        )}
      </div>
    </>
  );
};

export default Title;
