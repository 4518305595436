import React from "react";

export const phoneBlock = () => {
  switch (process.env.REACT_APP_MODE) {
    case "agent": //AGENTS.VIVA
    case "testing": //TRN
    case "local": //LOCALHOST
    case "consumer":
      return (
        <div className="contact-nav col-lg-2 d-flex align-items-center justify-content-center">
          <a className="text-white" href="tel:1300 872 835">
            <div className="d-flex">
              <div className="ph-code d-flex align-items-center">1300</div>
              <div className="phoneword d-flex align-items-center">
                TRAVEL
                <br />
                872835
              </div>
            </div>
            <span className="talkExpert d-none d-lg-block">
              Talk to an expert
            </span>
          </a>
        </div>
      ); //AU VIVA HOLS CONSUMER
    case "goholsnz": //GO HOLS NZ CONSUMER
      return (
        <div className="contact-nav col-lg-2 d-flex align-items-center justify-content-center">
          <a className="text-white" href="tel:0800 872 835">
            <div className="d-flex">
              <div className="ph-code d-flex align-items-center">0800</div>
              <div className="phoneword d-flex align-items-center">
                TRAVEL
                <br />
                872835
              </div>
            </div>
            <span className="talkExpert d-none d-lg-block">
              Talk to an expert
            </span>
          </a>
        </div>
      );
  }
};
