import Moment from "moment";

/**
 * coiunt difference between 2 dates
 * @param {*} date1
 * @param {*} date2
 */
export function daysdifference(date1, date2) {
  var ONEDAY = 1000 * 60 * 60 * 24;
  var date1_ms = date1.getTime();
  var date2_ms = date2.getTime();

  var diff = date2_ms - date1_ms;
  var difference_ms = 0;
  var diffDays = 0;
  if (diff > 0) {
    difference_ms = Math.abs(diff);
    diffDays = Math.round(difference_ms / ONEDAY);
  }

  return diffDays;
}

/**
 * count down
 * @param {*} date1
 * @param {*} date2
 */
export function countdown(saledate) {
  //var ONEDAY = 1000 * 60 * 60 * 24;
  var date1_ms = saledate.getTime();
  var today = new Date().getTime();

  return Math.round(date1_ms - today);
}

/**
 * get number of days(array) between dates
 * @param {*} startDate
 * @param {*} stopDate
 */
export function getDates(startDate, stopDate) {
  var dateArray = [];
  var currentDate = Moment(startDate);
  var stopDate = Moment(stopDate);

  while (currentDate <= stopDate) {
    let current = Moment(currentDate).format("YYYY-MM-DD");
    let format = new Date(
      current.split("-")[0],
      current.split("-")[1] - 1,
      current.split("-")[2]
    );

    dateArray.push(format);
    currentDate = Moment(currentDate).add(1, "days");
  }
  return dateArray;
}
