import drupal from "../apis/drupal";
import { DP_ACTIONS, DS_ACTIONS, BOOKING_STORE_STATUS } from "../actions/types";
import Parser from "html-react-parser";
import _ from "lodash";

export const fetchDefaults = () => async (dispatch, getState) => {
  const url =
    process.env.REACT_APP_MODE === "goholsnz" ? "/gonz/defaults" : "/defaults";

  const response = await drupal.get(url);
  dispatch({ type: DP_ACTIONS.FETCH_DEFAULTS, payload: response.data });
};

export const fetchPackages = () => async (dispatch, getState) => {
  // if (_.isEmpty(getState().drupal.packages)) {
  //   const response = await drupal.get("/campaigns");
  //   dispatch({ type: DP_ACTIONS.FETCH_PACKAGES, payload: response.data });
  // }

  const url =
    process.env.REACT_APP_MODE === "goholsnz"
      ? "/gonz/campaigns/all"
      : "/campaigns/all";
  const response = await drupal.get(url);

  const data = _.map(response.data, (pkg) => {
    let title = Parser(pkg.title)
      .trim()
      .replace(/[$!®@%:,'&*-]/g, "");

    let category = "";
    // if (
    //   pkg.field_deal_type !== "Events" &&
    //   pkg.field_deal_type !== "Group Tours" &&
    //   pkg.field_deal_type !== "Placeholder" &&
    // ) {
    //   category = "/packages";
    // }

    // if (pkg.field_deal_type === "Events") {
    //   category = "/events";
    // }

    // if (pkg.field_deal_type === "Group Tours") {
    //   category = "/group-tours";
    // }

    category = pkg.field_deal_type === "Placeholder" ? "/deals" : "/packages";

    //let title = pkg.title.replace(/[^a-z0-9,. ]/gi, "");
    return {
      ...pkg,
      field_path: title.replace(/\s+/g, "-").toLowerCase(),
      field_package_category: category,
    };
  });

  dispatch({ type: DP_ACTIONS.FETCH_PACKAGES, payload: data });
};

export const fetchPackage = (id) => async (dispatch) => {
  const response = await drupal.get(`/campaigns/${id}`);
  dispatch({ type: DP_ACTIONS.FETCH_PACKAGE, payload: response.data[0] });
};

export const fetchJournies = () => async (dispatch, getState) => {
  const url = "/uj/packagesa";
  const response = await drupal.get(url);

  const data = _.map(response.data, (jour) => {
    let title = Parser(jour.title)
      .trim()
      .replace(/[$!®@%:,'&*-]/g, "");

    let category = "";
    category = jour.field_deal_type === "Placeholder" ? "/deals" : "/packages";

    return {
      ...jour,
      field_path: title.replace(/\s+/g, "-").toLowerCase(),
      field_package_category: category,
    };
  });

  dispatch({ type: DP_ACTIONS.FETCH_JOURNEYS, payload: data });
};

export const fetchJourney = (id) => async (dispatch) => {
  const response = await drupal.get(`/uj/packages/${id}`);
  dispatch({ type: DP_ACTIONS.FETCH_JOURNEY, payload: response.data[0] });
};

export const fetchPeople = () => async (dispatch) => {
  const response = await drupal.get("/people");
  dispatch({ type: DP_ACTIONS.FETCH_PEOPLE, payload: response.data });
};

export const setBookingItem = (data) => (dispatch) => {
  dispatch({ type: BOOKING_STORE_STATUS.STORE_SAVE_ITEM, payload: data });
};

// export const fetchDestinationWithDetail = () => async (dispatch, getState) => {
//   await dispatch(fetchDestinations());

//   _.chain(getState().destination)
//     .map("field_country_code")
//     .uniq()
//     .forEach(code => dispatch(fetchCountryList(code)))
//     .value(); //.value means execute steps
// };

// export const fetchDestinations = () => async dispatch => {
//   const response = await drupal.get("/destinations");
//   dispatch({ type: DS_ACTIONS.FETCH_DESTINATIONS, payload: response.data });
// };

// export const fetchCountryList = code => async dispatch => {
//   const response = await vera.get(`/getCountryInfo/${code}`);
//   dispatch({ type: DS_ACTIONS.FETCH_COUNTRY_LIST, payload: response.data });
// };
