import React from "react";

const Ribbon = props => {
  return (
    <div>
      <div className={`${props.classType}-ribbon`}>
        <span></span>
        <h3>{props.title}</h3>
      </div>
    </div>
  );
};
export default Ribbon;
