import React from "react";
import Helmet from "react-helmet";

const SEO = ({ title, description }) => {
  const defaultTitle = "Viva Holidays";
  const defaultDescription =
    "Viva! holidays offer an extensive choice of flexible travel with a great range of accommodation that's only a click away.";
  return (
    <Helmet>
      <title>{title ? `${title} | ${defaultTitle}` : defaultTitle}</title>
      <meta
        name="description"
        content={description ? description : defaultDescription}
      />
    </Helmet>
  );
};

export { SEO };
