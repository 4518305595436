import _ from "lodash";
import { DP_ACTIONS, DS_ACTIONS, BOOKING_STORE_STATUS } from "../actions/types";

const initialState = {
  loading: false,
  packages: null,
  package: null,
  journeys: null,
  journey: null,
  destinations: null,
  country: null,
  defaults: null,
  cms_pages: null,
  error: null,
  bookingForm: null,
  paymentReply: null,
  tpReply: null,
  item: null,
};

export const migrations = {
  1: (previousVersionState) => ({
    loading: previousVersionState.loading,
    packages: previousVersionState.packages,
    package: previousVersionState.package,
    journeys: previousVersionState.journeys,
    journey: previousVersionState.journey,
    destinations: previousVersionState.destinations,
    country: previousVersionState.country,
    defaults: previousVersionState.defaults,
    cms_pages: previousVersionState.cms_pages,
    error: previousVersionState.error,
    bookingForm: previousVersionState.bookingForm,
    paymentReply: previousVersionState.paymentReply,
    tpReply: previousVersionState.tpReply,
    item: previousVersionState.item,
  }),
};

const drupalReducer = (state = initialState, action) => {
  switch (action.type) {
    case DP_ACTIONS.FETCH_DEFAULTS:
      return {
        ...state,
        defaults: action.payload[0],
        cms_pages: action.payload[0].view_1,
      };
    case DP_ACTIONS.FETCH_PACKAGES:
      return {
        ...state,
        packages: action.payload,
      };
    case DP_ACTIONS.FETCH_PACKAGE:
      return {
        ...state,
        package: action.payload,
        // package: { ...state.package, ..._.mapKeys(action.payload, "nid") }
      };
    case DP_ACTIONS.FETCH_JOURNEYS:
      return {
        ...state,
        journeys: action.payload,
      };
    case DP_ACTIONS.FETCH_JOURNEY:
      return {
        ...state,
        journey: action.payload,
      };
    case DS_ACTIONS.FETCH_ALL_COUNTRIES:
      return {
        ...state,
        destinations: _.mapKeys(action.payload, "field_country_code"),
      };
    case DS_ACTIONS.FETCH_COUNTRY_DETAIL:
      return {
        ...state,
        country: action.payload,
      };
    case BOOKING_STORE_STATUS.STORE_SAVE_BOOKING_DETAILS:
      return {
        ...state,
        bookingForm: { ...action.payload },
      };
    case BOOKING_STORE_STATUS.STORE_SAVE_PAYMENT_DETAILS:
      return {
        ...state,
        paymentReply: { ...action.payload },
      };
    case BOOKING_STORE_STATUS.STORE_SAVE_BOOKING_DETAILS_TP:
      return {
        ...state,
        tpReply: { ...action.payload },
      };
    // case BOOKING_STORE_STATUS.STORE_SAVE_ITEM:
    //   return {
    //     ...state,
    //     item: { ...state.item, [action.payload.itemNo]: { ...action.payload } },
    //   };
    case BOOKING_STORE_STATUS.STORE_SAVE_ITEM:
      return {
        ...state,
        item: { ...action.payload },
      };
    default:
      return state;
  }
};

export const getDrupalChanges = (state) => state.persistedStore.drupal;

export default drupalReducer;
