import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
//import "core-js";
import React from "react";
import App from "./components/App";
import { loader } from "./helpers";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import BackgroundContextProvider from "./context/BackgroundContext";
import store, { persistor } from "./store";

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={loader()} persistor={persistor}>
      <BackgroundContextProvider>
        <App />
      </BackgroundContextProvider>
    </PersistGate>
  </Provider>,
  document.querySelector("#root")
);
