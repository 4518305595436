// drupal constants
export const DP_ACTIONS = {
  FETCH_DEFAULTS: "FETCH_DEFAULTS",
  FETCH_JOURNEY: "FETCH_JOURNEY",
  FETCH_JOURNEYS: "FETCH_JOURNEYS",
  FETCH_PACKAGE: "FETCH_PACKAGE",
  FETCH_PACKAGES: "FETCH_PACKAGES",
  FETCH_PEOPLE: "FETCH_PEOPLE",
};

//destination constants
export const DS_ACTIONS = {
  FETCH_DESTINATIONS: "FETCH_DESTINATIONS",
  FETCH_COUNTRY_LIST: "FETCH_COUNTRY_LIST",
  FETCH_COUNTRY_CITIES: "FETCH_COUNTRY_CITIES",
  FETCH_COUNTRY_DETAIL: "FETCH_COUNTRY_DETAIL",
  FETCH_ALL_COUNTRIES: "FETCH_ALL_COUNTRIES",
  FETCH_CITY_DETAIL: "FETCH_CITY_DETAIL",
  FETCH_CITY_ENTRIES: "FETCH_CITY_ENTRIES",
};

//mai/man constants
export const MM_ACTIONS = {
  SEND_EMAIL: "SEND_EMAIL",
};

//booking constants

export const BOOKING_STORE_STATUS = {
  STORE_TRAVEL_RANGE_FROM: "STORE_BOOKING_TRAVEL_RANGE_FROM",
  STORE_TRAVEL_RANGE_TO: "STORE_BOOKING_TRAVEL_RANGE_TO",
  STORE_TRAVELLERS_ADULT: "STORE_BOOKING_TRAVELLERS_ADULT",
  STORE_TRAVELLERS_CHILD: "STORE_BOOKING_TRAVELLERS_CHILD",
  STORE_PASSENGERS: "STORE_BOOKING_PASSENGERS",
  CLEAR_PASSENGERS: "CLEAR_PASSENGERS",
  STORE_ADDRESS: "STORE_BOOKING_ADDRESS",
  STORE_PRICE: "STORE_BOOKING_PRICE",
  STORE_GET_OPT_RESPONSE: "STORE_BOOKING__GET_OPT_RESPONSE",
  STORE_BOOKING_INFO_RESPONSE: "STORE_BOOKING_INFO_RESPONSE",
  STORE_BOOKING_REDIRECT: "STORE_BOOKING_REDIRECT",
  STORE_SAVE_BOOKING_DETAILS: "STORE_BOOKING_SAVE_DETAILS",
  STORE_SAVE_PAYMENT_DETAILS: "STORE_SAVE_PAYMENT_DETAILS",
  STORE_SAVE_BOOKING_DETAILS_TP: "STORE_SAVE_BOOKING_DETAILS_TP",
  STORE_SAVE_ITEM: "STORE_SAVE_ITEM",
};

export const BOOKING_VALIDATE_STATUS = {
  BEGIN: "VALIDATE_BOOKING_BEGIN",
  SUCCESS: "VALIDATE_BOOKING_SUCCESS",
  FAILURE: "VALIDATE_BOOKING_FAILURE",
};

export const TOGGLE_MODAL = {
  PAYMENT: "TOGGLE_MODAL_PAYMENT",
  INFO: "TOGGLE_MODAL_INFO",
};

export const TOGGLE_CHECK_AVAIL_BUTTON = {
  TOGGLE: "TOGGLE_CHECK_AVAIL_BUTTON",
};

export const BOOKING_AVAILABILITY_CHECK_STATUS = {
  BEGIN: "BOOKING_AVAILABILITY_CHECK_BEGIN",
  SUCCESS: "BOOKING_AVAILABILITY_CHECK_SUCCESS",
  FAILURE: "BOOKING_AVAILABILITY_CHECK_FAILURE",
  MULTI_BEGIN: "BOOKING_MULTI_AVAILABILITY_BEGIN",
  MULTI_SUCCESS: "BOOKING_MULTI_AVAILABILITY_SUCCESS",
  MULTI_FAILURE: "BOOKING_MULTI_AVAILABILITY_FAILURE",
  BLOCKED_DATES: "BOOKING_MULTI_AVAILABILITY_BLOCKED_DATES",
};

export const BOOKING_FETCH_OPTION_STATUS = {
  BEGIN: "BOOKING_FETCH_OPTION_BEGIN",
  SUCCESS: "BOOKING_FETCH_OPTION_SUCCESS",
  FAILURE: "BOOKING_FETCH_OPTION_FAILURE",
};

export const BOOKING_ADD_STATUS = {
  BEGIN: "BOOKING_ADD_BEGIN",
  SUCCESS: "BOOKING_ADD_SUCCESS",
  FAILURE: "BOOKING_ADD_FAILURE",
};

export const SEND_EMAIL_STATUS = {
  BEGIN: "SEND_EMAIL_BEGIN",
  SUCCESS: "SEND_EMAIL_SUCCESS",
  FAILURE: "SEND_EMAIL_FAILURE",
};
