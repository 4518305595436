const local = {
  CONFIG: {
    SITE_CODE: "LOCAL",
    COPYRIGHT_TAG: "Viva Holidays Pty Ltd ABN 78 634 662 294A",
    SECONDARY_LOGO_FILENAME: "viva_vertical_logo.png",
    LOGO_FILENAME: "viva_vertical_logo.png",
    SITE_BASE: "https://agents.vivaholidays.com.au",
    SITE_TITLE: "Viva! Holidays",
    TEST_EMAIL: "ken.baker@helloworld.com.au",
    EMAIL: "enquiries@ultimatejourneys.com",
    PHONE: "1300 130 524",
    CHAT: true,
    CURRENCY: "TPZ",
    BASEURL: "http://localhost:3000",
    BOOKINGSALLOWED: true,
    GA_TRACKER: "UA-X69376467-X",
    FB_PIXEL: "1796625273789261",
    VERA_BASE: "https://vera.trn.secure-travel.net",
    VERA_API: "https://vera.trn.secure-travel.net/vera/api/",
    PAYMAN_REDIRECT:
      "https://vera.trn.secure-travel.net/payman/api/pay/redirectAccess",
  },
};

const testing = {
  CONFIG: {
    SITE_CODE: "TEST",
    COPYRIGHT_TAG: "Viva Holidays Pty Ltd ABN 78 634 662 294B",
    SECONDARY_LOGO_FILENAME: "viva_vertical_logo.png",
    LOGO_FILENAME: "viva_vertical_logo.png",
    SITE_BASE: "https://agents.vivaholidays.com.au",
    SITE_TITLE: "Viva! Holidays",
    TEST_EMAIL: "ken.baker@helloworld.com.au",
    EMAIL: "enquiries@ultimatejourneys.com",
    PHONE: "1300 130 524",
    CHAT: true,
    CURRENCY: "AUD",
    BASEURL: "http://viva-consumer.trn.travel-bookings.net:8080",
    BOOKINGSALLOWED: true,
    GA_TRACKER: "UA-X69376467-X",
    FB_PIXEL: "1796625273789261",
    VERA_BASE: "https://vera.trn.secure-travel.net",
    VERA_API: "https://vera.trn.secure-travel.net/vera/api/",
    PAYMAN_REDIRECT:
      "https://vera.trn.secure-travel.net/payman/api/pay/redirectAccess",
  },
};

const consumer = {
  CONFIG: {
    SITE_CODE: "CONSUMER",
    COPYRIGHT_TAG: "Viva Holidays Pty Ltd ABN 78 634 662 294C",
    SECONDARY_LOGO_FILENAME: "viva_vertical_logo.png",
    LOGO_FILENAME: "viva_vertical_logo.png",
    SITE_BASE: "https://vivaholidays.com.au",
    SITE_TITLE: "Viva! Holidays",
    TEST_EMAIL: "ken.baker@helloworld.com.au",
    EMAIL: "reservations@vivaholidays.com.au",
    PHONE: "1300 872 835",
    CHAT: false,
    CURRENCY: "AUD",
    BASEURL: "https://vivaholidays.com.au",
    BOOKINGSALLOWED: true,
    GA_TRACKER: "UA-169376467-1",
    FB_PIXEL: "1796625273789265",
    VERA_BASE: "https://vera.secure-travel.net",
    VERA_API: "https://vera.secure-travel.net/vera/api/",
    PAYMAN_REDIRECT:
      "https://vera.secure-travel.net/payman/api/pay/redirectAccess",
  },
};

const goholsnz = {
  CONFIG: {
    SITE_CODE: "GO",
    COPYRIGHT_TAG: "GO Holidays Limited",
    SECONDARY_LOGO_FILENAME: "go_holidays_logo.png",
    LOGO_FILENAME: "go_holidays_logo.png",
    SITE_BASE: "https://agents.vivaholidays.com.au",
    SITE_TITLE: "Go Holidays",
    TEST_EMAIL: "ken.baker@helloworld.com.au",
    EMAIL: "enquiries@ultimatejourneys.com",
    PHONE: "1300 130 524",
    CHAT: true,
    CURRENCY: "NZD",
    BOOKINGSALLOWED: true,
    GA_TRACKER: "UA-X69376467-X",
    FB_PIXEL: "1796625273789261",
    VERA_BASE: "https://vera.trn.secure-travel.net",
    VERA_API: "https://vera.trn.secure-travel.net/vera/api/",
    PAYMAN_REDIRECT:
      "https://vera.trn.secure-travel.net/payman/api/pay/redirectAccess",
  },
};

const agent = {
  CONFIG: {
    SITE_CODE: "AGENT",
    COPYRIGHT_TAG: "Viva Holidays Pty Ltd ABN 78 634 662 294D",
    SECONDARY_LOGO_FILENAME: "viva_vertical_logo.png",
    LOGO_FILENAME: "viva_vertical_logo.png",
    SITE_BASE: "https://agents.vivaholidays.com.au",
    SITE_TITLE: "Agents.Viva",
    TEST_EMAIL: "ken.baker@helloworld.com.au",
    EMAIL: "enquiries@ultimatejourneys.com",
    PHONE: "1300 130 524",
    CHAT: true,
    CURRENCY: "NZD",
    BOOKINGSALLOWED: true,
    GA_TRACKER: "UA-X69376467-X",
    FB_PIXEL: "1796625273789261",
    VERA_BASE: "https://vera.trn.secure-travel.net",
    VERA_API: "https://vera.trn.secure-travel.net/vera/api/",
    PAYMAN_REDIRECT:
      "https://vera.trn.secure-travel.net/payman/api/pay/redirectAccess",
  },
};

let setConfig = () => {
  switch (process.env.REACT_APP_MODE) {
    case "consumer":
      return consumer; //AU VIVA HOLS CONSUMER
    case "agent": //AGENTS.VIVA
      return agent;
    case "testing": //TRN
      return testing;
    case "local": //LOCALHOST
      return local;
    case "goholsnz": //GO HOLS NZ CONSUMER
      return goholsnz;
  }
};

const config = setConfig();

export default {
  // Add common config values here

  ...config,
};
