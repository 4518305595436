import React, { useEffect, useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Router, Switch } from "react-router-dom";
//import "../assets/sass/App.scss";
//import "../assets/sass/main.scss";
// import "../assets/sassGo/App.scss";
// import "../assets/sassGo/main.scss";
import "../assets/css/bootstrap.min.css";
import "../assets/css/all.css";
import ScrollToTop from "react-router-scroll-top";
import history from "../history";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import Navigation from "../components/layout/Navigation";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import ChatButton from "../elements/ChatButton";
import Routes from "./Routes";
import _ from "lodash";
import { fetchDefaults, fetchPackages, fetchJournies } from "../actions";
import { fetchAllCountries } from "../actions/destinations";
import PersistedContext from "../context/persistedContext";
import { BackgroundContext } from "../context/BackgroundContext";
import { default as BASE } from "../config";
//import { setBackground } from "../helpers";
//import { cssImports } from "../helpers/cssImports";

ReactGA.initialize(BASE.CONFIG.GA_TRACKER);

const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};
ReactPixel.init(BASE.CONFIG.FB_PIXEL, options);

history.listen((location, action) => {
  //console.log(location.pathname + location.search);
  ReactGA.pageview(location.pathname + location.search);
  ReactPixel.pageView();
});

const App = () => {
  const persistedContext = useSelector((state) =>
    process.env.REACT_APP_MODE === "goholsnz"
      ? state.goPersist
      : state.vivaPersist
  );
  const { setImage } = useContext(BackgroundContext);

  const dispatch = useDispatch();

  useEffect(() => {
    if (process.env.REACT_APP_MODE === "goholsnz") {
      require("../assets/sassGo/App.scss");
      require("../assets/sassGo/main.scss");
    } else {
      require("../assets/sass/App.scss");
      require("../assets/sass/main.scss");
    }
  }, []);

  useEffect(() => {
    //setImage(history.location.pathname);

    setBackground(
      setImage,
      persistedContext.defaults,
      history.location.pathname
    );
    history.listen((location) => {
      setBackground(setImage, persistedContext.defaults, location.pathname);
    });
  }, [persistedContext.defaults]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.pageView();
    //console.log(window.location.pathname + window.location.search);
    dispatch(fetchDefaults());
    dispatch(fetchPackages());
    dispatch(fetchAllCountries());
    dispatch(fetchJournies());
  }, []);

  const setBackground = () => {
    const { defaults } = persistedContext;
    if (defaults) {
      const path = history.location.pathname;
      const loc = path.includes("/news")
        ? "/news"
        : path.includes("/deals")
        ? "/deals"
        : path;
      const page = _.find(defaults.view_2, ["field_page_path", loc]);
      setImage(loc, page ? page.field_fc_background_image : null);
      //setImage(path, page);
    }
  };
  return (
    <Router history={history}>
      <ScrollToTop>
        <PersistedContext.Provider value={persistedContext}>
          <Header />
          {BASE.CONFIG.CHAT && <ChatButton />}
          <Navigation />
          <Switch>
            <Routes />
          </Switch>
          <Footer />
        </PersistedContext.Provider>
      </ScrollToTop>
    </Router>
  );
};

export default App;
