import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import {
  HomeComponent,
  DestinationListComponent,
  DestinationCityComponent,
  DestinationCityDetailComponent,
  BrochureComponent,
  PolicyComponent,
  AboutComponent,
  BookWithConfidenceComponent,
  ContactComponent,
  NewsListComponent,
  NewsPageComponent,
  BookingConfirm,
  BookingFormContainer,
} from "../seo";
import PackageRoutes from "./routes/packages/PackageRoutes";
import UltimateJourneysRoutes from "./routes/ultimate-journeys/UltimateJourneyRoutes";
import EscortedToursRoutes from "./routes/escorted-tours/EscortedToursRoutes";
import { loader } from "../helpers";
import {
  GoogleReCaptchaProvider,
  withGoogleReCaptcha,
} from "react-google-recaptcha-v3";

const Routes = () => {
  return (
    <div id="main" className="container main-content-area">
      <Suspense fallback={loader()}>
        <Route path="/" exact component={HomeComponent} />
        <Route path="/about-us" exact component={AboutComponent} />
        <Route path="/book-with-confidence" exact component={BookWithConfidenceComponent} />
        <Route path="/contact" exact component={ContactComponent} />
        <Route path="/news" exact component={NewsListComponent} />
        <Route path="/news/:title/:id" exact component={NewsPageComponent} />
        <Route exact path="/brochures" component={BrochureComponent} />
        <Route
          exact
          path="/destinations"
          component={DestinationListComponent}
        />
        <Route
          exact
          path="/destinations/:country"
          component={DestinationCityComponent}
        />
        <Route
          exact
          path="/destinations/:country/:city"
          component={DestinationCityDetailComponent}
        />
        <Route
          exact
          path="/policies/booking-conditions"
          component={PolicyComponent}
        />
        <GoogleReCaptchaProvider reCaptchaKey="6LdDgeMUAAAAAFHPq678bKneG9Ydo2NLdyW9WhH3">
          <Route
            exact
            path="/booking"
            render={(props) => <BookingFormContainer {...props} />}
          />
          <Route
            exact
            path="/booking-confirmation"
            component={BookingConfirm}
          />

          <PackageRoutes />
          <UltimateJourneysRoutes />
          <EscortedToursRoutes />
        </GoogleReCaptchaProvider>
      </Suspense>
    </div>
  );
};

export default Routes;
